/* eslint-disable unicorn/prefer-add-event-listener */
import { useState, useEffect } from "react";
import { path } from "ramda";

import actions from "../actions";
import { store } from "../plugins/initialize-store";

const useSocket = (namespace, room, silently) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);

	const handleError = (e) => {
		setLoading(false);
		setError(e);
		store.dispatch(actions.ui.addError({}));
	};

	const initSocket = () => {
		if (!silently) {
			setLoading(true);
		}

		const state = store.getState();
		const token = path(["auth", "token"], state);
		const connection = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_API}?token=${token}`);

		connection.onopen = () => {
			if (namespace) {
				connection.send(JSON.stringify({
					device: namespace,
					room,
					type: "logs",
				}));
			}
		};

		connection.onmessage = (msg) => {
			let msgData = {};
			try {
				msgData = JSON.parse(msg.data);
			} catch { /* empty */ }

			switch (msgData.type) {
				case "logs":
					setLoading(false);
					setData(msgData.lines);
					break;
				case "error":
					handleError(msgData.message);
					break;
				default:
					break;
			}
		};

		connection.onerror = (err) => {
			console.log(err);
			handleError(err);
		};

		return connection;
	};

	useEffect(() => {
		const io = initSocket();
		return () => {
			io.close();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [namespace, room]);

	return [data, loading, error, setData];
};

export default useSocket;
