import { api, prefixUrl } from "../lib/api-adapter";

const appsApi = api.extend({ prefixUrl: prefixUrl("apps") });

export const getSuggestions = () => appsApi.get("suggestions").json();

export const getNew = () => appsApi.get("new").json();

export const getApps = (deviceId) => appsApi.get(`${deviceId}/apps`).json();

export const getRunning = (deviceId) => appsApi.get(`${deviceId}/running`).json();

export const getMyApps = (userId) => appsApi.get("", { searchParams: { owner: userId } }).json();

export const getInstalled = (deviceId) => appsApi.get(`${deviceId}/installed`).json();

export const getAllInstalled = (deviceId) => appsApi.get(`${deviceId}/allInstalled`).json();

export const getNewInstalled = (deviceId) => appsApi.get(`${deviceId}/newInstalled`).json();

export const getAll = () => appsApi.get("").json();

export const getApp = (appId) => appsApi.get(appId).json();

export const getAppByName = (appName) => appsApi.get(`name/${appName}`).json();

export const installApp = (appId, deviceId, data) => appsApi.post(`${appId}/devices/${deviceId}/install`, { json: data }).json();

export const uninstallApp = (installedId) => appsApi.post(`${installedId}/uninstall`).json();

export const uninstallLocalApp = (deviceId, app) => appsApi.post(`${deviceId}/uninstallApp`, { json: { app } });

export const startApp = (installedId) => appsApi.patch(`${installedId}/start`).json();

export const stopApp = (installedId) => appsApi.patch(`${installedId}/stop`).json();

export const uploadApp = (data) => appsApi.post("upload", { body: data, timeout: 60_000 }).json();

export const updateApp = (appId, data) => appsApi.patch(`${appId}`, { body: data, timeout: 60_000 }).json();

export const rateApp = (appId, rating) => appsApi.post(`${appId}/rating`, { body: { rating } }).json();

export const deleteApp = (appId) => appsApi.delete(appId).json();

export const downloadApp = (appId) => appsApi.get(`${appId}/downloading`).blob();

export const fastDeploy = (deviceId, data) => appsApi.post(`${deviceId}/fastDeploy`, { body: data });
