const ui = {
	addError: (error) => ({
		type: "UI.ADD_ERROR",
		payload: error,
	}),
	removeError: (id) => ({
		type: "UI.REMOVE_ERROR",
		payload: id,
	}),
	triggerAlert: (message) => ({
		type: "UI.TRIGGER_ALERT",
		payload: message,
	}),
	disableAlert: () => ({ type: "UI.DISABLE_ALERT" }),

	changeView: (view) => ({
		type: "UI.CHANGE_VIEW",
		payload: view,
	}),
};

export default ui;
