import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";

import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "./index.css";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "react-responsive-modal/styles.css";

import theme from "./lib/theme";
import loadPlugins from "./plugins";
import i18n from "./components/i18n";
import AppMain from "./components/AppMain";
import AppHeader from "./components/AppHeader";
import AppRoutes from "./components/AppRoutes";
import AppFooter from "./components/AppFooter";
import ErrorBoundary from "./components/ErrorBoundary";
import * as serviceWorkerRegistration from "./service-worker-registration";

const App = loadPlugins(({ store }) => (
	<Router basename={`/${i18n.language}`}>
		<I18nextProvider i18n={i18n}>
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<ErrorBoundary>
						<AppHeader />
						<AppMain>
							<AppRoutes />
						</AppMain>
						<AppFooter />
					</ErrorBoundary>
				</Provider>
			</ThemeProvider>
		</I18nextProvider>
	</Router>
));

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, enabled: process.env.NODE_ENV === "production" });

ReactDOM.render(<App />, document.querySelector("#root"));

serviceWorkerRegistration.register();
