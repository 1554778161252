import {
	Navbar,
	NavbarGroup,
	NavbarHeading,
	Button,
	Text,
	Icon,
	AnchorButton,
	MenuItem,
	Menu,
} from "@blueprintjs/core";
import { path } from "ramda";
import Cookies from "js-cookie";
import propTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fontSize, system } from "styled-system";
import { Popover2 as Popover, Tooltip2 as Tooltip } from "@blueprintjs/popover2";
import { useGoogleLogout } from "react-google-login";

import actions from "../actions";
import user from "../assets/user.png";
import sitiesWhite from "../assets/Sythes_white.png";
import sitiesLogout from "../assets/Sities_logout.png";
import sitiesSettings from "../assets/Sities_settings.png";
import sitiesPlatform from "../assets/Sities_platform.png";
import { checkIsAuthenticated, getRoles, getTokenId, updateCookie } from "../lib/utilities";

import SearchBar from "./SearchBar";

const Brand = styled.img.attrs({ src: sitiesWhite, alt: "sities icon" })`
	object-fit: cover;
	height: 3.5em;
	cursor: pointer;
	margin-left: 5%;
`;

const StyledNavbar = styled(Navbar)`
	background-color: ${path(["theme", "colors", "primary"])};
	height: 100px;
`;

const StyledNavbarGroup = styled(NavbarGroup)`
	height: 100px;
	width: ${(props) => props.width};
	padding-right: 10px;
	justify-content: ${(props) => props.justifycontent};
`;

const StyledNavbarHeading = styled(NavbarHeading)`
	${fontSize}
`;

const StyledIcon = styled.img.attrs((props) => ({ src: props.icon }))`
	width: 3.3vw;
	height: 3.3vw;
	max-height: 80px;
	position: relative;
	right: 50%;
	transform: translateX(50%);
	margin-bottom: 5px;
`;

const StyledText = styled(Text)`
	color: ${path(["theme", "colors", "secondary"])};
	${fontSize};
	text-align: center;
	${system({
		display: {
			property: "display",
			scale: "displays",
		},
	})};
	font-weight: 900;
`;

const NavItem = ({ Wrapper, icon, text, ...rest }) => (
	<Wrapper
		minimal
		{...rest}
	>
		<StyledIcon icon={icon} />
		<StyledText fontSize={[0, 1]} display={[0, 0, 1]}>
			{text}
		</StyledText>
	</Wrapper>
);

const StyledMenuItem = styled(MenuItem)`
	color: ${path(["theme", "colors", "primary"])}!important;
	background-color: ${path(["theme", "colors", "secondary"])}!important;
	font-weight: 600;
	outline: none;
`;

const NavbarMenuContainer = styled.div`
	display: flex;
`;

const NavbarMenu = styled.div`
	${system({
		display: {
			property: "display",
			scale: "displays",
		},
	})};
`;

const AppHeader = ({ isAuthenticated, roles, tokenId, view, changeView, clearAuth, history: { push: navigate } }) => {
	const { t } = useTranslation();

	const { signOut } = useGoogleLogout({ clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID });

	const buttons = [
		{
			Wrapper: Button,
			icon: sitiesSettings,
			lefticon: "cog",
			tooltip: t("AppHeader.role.tooltip"),
			text: t("AppHeader.role.text"),
		},
		{
			Wrapper: AnchorButton,
			icon: sitiesPlatform,
			lefticon: "send-to",
			tooltip: t("AppHeader.platform.tooltip"),
			text: t("AppHeader.platform.text"),
			href: process.env.REACT_APP_PLATFORM_URI,
		},
		{
			Wrapper: Button,
			icon: user,
			lefticon: "user",
			tooltip: t("AppHeader.profile.tooltip"),
			text: t("AppHeader.profile.text"),
			onClick: () => navigate("/preferences"),
		},
		{
			Wrapper: Button,
			icon: sitiesLogout,
			lefticon: "log-out",
			text: t("AppHeader.logout"),
			onClick: () => {
				if (tokenId) {
					signOut();
				}

				Cookies.remove("auth", { path: "/" });
				clearAuth();
				navigate("/signin");
				window.location.reload();
			},
		},
	];

	const menuButtons = [
		{
			icon: "person",
			text: t("AppHeader.owner"),
			onClick: () => {
				changeView("owner");
				updateCookie("ui", { view: "owner" });
			},
			disabled: view === "owner",
		},
		{
			icon: "code",
			text: t("AppHeader.developer"),
			onClick: () => {
				if (roles.includes("developer") || roles.includes("producer")) {
					changeView("developer");
					updateCookie("ui", { view: "developer" });
				}
			},
			disabled: view === "developer",
		},
		{
			icon: "console",
			text: t("AppHeader.producer"),
			onClick: () => {
				if (roles.includes("producer")) {
					changeView("producer");
					updateCookie("ui", { view: "producer" });
				}
			},
			disabled: view === "producer",
		},
	];

	const menu = (
		<Menu style={{ listStyleType: "none" }}>
			{buttons.map((button, i) => (
				// if roles=["owner"] then don't show the first two buttons
				roles.length === 1 && (i === 0 || i === 1) ? null : (
					<StyledMenuItem
						key={button.text}
						href={button.href}
						text={button.text}
						icon={button.lefticon}
						intent="primary"
						onClick={button.onClick}
					>
						{i === 0 ? ( // change role button
							menuButtons.map((menuButton, index) => {
								// don't show the role producer if roles=["owner", developer]
								if (index === 2 && !roles.includes("producer")) return null;
								return (
									<StyledMenuItem
										{...menuButton}
										key={menuButton.text}
										intent="primary"
									/>
								);
							})) : null}
					</StyledMenuItem>
				)))}
		</Menu>
	);

	const submenu = (
		<Menu style={{ listStyleType: "none" }}>
			{menuButtons.map((menuButton, index) => {
				// don't show the role producer if roles=["owner", developer]
				if (index === 2 && !roles.includes("producer")) return null;
				return (
					<StyledMenuItem
						{...menuButton}
						key={menuButton.text}
						intent="primary"
					/>
				);
			})}
		</Menu>
	);

	return (
		<StyledNavbar>
			<StyledNavbarGroup align="left" width="30%">
				<StyledNavbarHeading fontSize={[0, 3, 6]}>
					<Brand />
				</StyledNavbarHeading>
			</StyledNavbarGroup>
			{isAuthenticated && (
				<StyledNavbarGroup
					align="right"
					width="70%"
					justifycontent="space-evenly"
				>
					<SearchBar />
					<NavbarMenuContainer>
						<NavbarMenu display={[0, 1]}>
							{roles.length > 1 ? ( // if roles !== ["owner"]
								<Popover content={submenu} placement="bottom">
									<Tooltip content={t("AppHeader.role.tooltip")} placement="bottom">
										<Button minimal>
											<StyledIcon icon={sitiesSettings} />
											<StyledText fontSize={[0, 1]} display={[0, 0, 1]}>
												{t("AppHeader.role.text")}
											</StyledText>
										</Button>
									</Tooltip>
								</Popover>
							) : null}
							{buttons.slice(1, 4).map((button, i) => (
								// if roles=["owner"]
								roles.length === 1 && i === 0 ? null : (
									<Tooltip key={button.text} content={button.tooltip} placement="bottom">
										<NavItem
											{...button}
										/>
									</Tooltip>
								)))}
						</NavbarMenu>
						<NavbarMenu display={[1, 0]}>
							<Popover content={menu} placement="bottom-end" autoFocus={false}>
								<Button minimal>
									<Icon icon="menu" style={{ color: "white" }} />
								</Button>
							</Popover>
						</NavbarMenu>
					</NavbarMenuContainer>
				</StyledNavbarGroup>
			)}
		</StyledNavbar>
	);
};

AppHeader.propTypes = { isAuthenticated: propTypes.bool.isRequired };

const mapState = (state) => ({ isAuthenticated: checkIsAuthenticated(state), roles: getRoles(state), tokenId: getTokenId(state), view: path(["ui", "view"], state) });

const mapDispatch = (dispatch) => ({
	clearAuth: () => {
		dispatch(actions.auth.clear());
	},
	changeView: (data) => {
		dispatch(actions.ui.changeView(data));
	},
});

export default connect(mapState, mapDispatch)(withRouter(AppHeader));
