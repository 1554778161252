import { api, prefixUrl } from "../lib/api-adapter";

const elsaApi = api.extend({ prefixUrl: prefixUrl("devices") });

export const getDevice = (id) => elsaApi.get(id).json();

export const getDevices = () => elsaApi.get("").json();

export const getAllDevices = () => elsaApi.get("all").json();

export const createDevice = (data) => elsaApi.patch("linkUser", { json: data }).json();

export const updateDevice = (id, name, email) => elsaApi.put(`${id}/updateDevice`, { json: { name, email } }).json();

export const linkUser = (id, userId) => elsaApi.put(`${id}/linkUser/${userId}`).json();

export const unlinkUser = (id) => elsaApi.put(`${id}/unlinkUser`).json();

export const deviceVolume = ({ id, volume }) => elsaApi.post(`${id}/volume`, { json: { volume } }).json();

export const reboot = (id) => elsaApi.get(`${id}/reboot`).json();

export const shutdown = (id) => elsaApi.get(`${id}/shutdown`).json();

export const getSsh = (id) => elsaApi.get(`${id}/ssh`).json();

export const upgradeComponent = (id, data, rebootOpt) => elsaApi.post(`${id}/upgradeComponent`, { json: { components: [data], reboot: rebootOpt } });

export const upgradeRelease = (id, releaseId) => elsaApi.post(`${id}/upgrade/${releaseId}`);

export const createNewDevice = (serial, name, description, mac, type, version, username, password) => elsaApi.post("",
	{ json: { serial, name, description, mac, type, version, brokerParams: { username, password } } });

export const editDevice = (id, serial, name, description, mac, type, version, username, password) => elsaApi.put(id,
	{ json: { serial, name, description, mac, type, version, brokerParams: { username, password } } });

export const deleteDevice = (id) => elsaApi.delete(id);

export const getVersion = (id) => elsaApi.get(`${id}/version`).json();

export const getFullVersion = (id) => elsaApi.get(`${id}/fullVersion`).json();

export const createCalendarEvent = (id, eventInfo) => elsaApi.post(`${id}/calendarEvent`, { json: { eventInfo } });

export const deleteEvent = (calendarId, eventId) => elsaApi.post("deleteEvent", { json: { calendarId, eventId } });
