import { path } from "ramda";
import ky from "ky/distribution/index";

import actions from "../actions";
import { store } from "../plugins/initialize-store";

import { checkIsAuthenticated } from "./utilities";

const serverUrl = process.env.REACT_APP_SERVER_API;

export const prefixUrl = (route) => `${serverUrl}/${route}`;

export const api = ky.create({
	timeout: 20_000,
	credentials: "include",
	hooks: {
		beforeRequest: [
			(req) => {
				const state = store.getState();
				if (checkIsAuthenticated(state)) {
					req.headers.set("Authorization", `Bearer ${path(["auth", "token"], state)}`);
				}
			},
		],
		afterResponse: [
			async (_, __, res) => {
				try {
					if (!res.ok) {
						const json = await res.clone().json();
						store.dispatch(actions.ui.addError(json));
					}
				} catch (error) {
					console.error(error);
				}

				return res;
			},
		],
	},
});
