import { path } from "ramda";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { layout, fontSize } from "styled-system";
import { Box, Text } from "rebass/styled-components";

import europeFooter from "../assets/europe-footer.jpg";

const StyledBox = styled(Box)`
	background-color: ${path(["theme", "colors", "secondary"])};
	text-align: center;
	padding: 20px;
`;

const StyledFooter = styled.img.attrs({ width: [200, 300, 400], src: europeFooter, alt: "europe footer" })`${layout};
	object-fit: cover;
`;

const StyledText = styled(Text).attrs({ fontSize: ["6px", "8px", 1] })`${fontSize}
	color: ${path(["theme", "colors", "darkGrey"])};
	margin: 20px;
	white-space: pre-line;
`;
const AppFooter = () => {
	const { t } = useTranslation();
	return (
		<Box style={{ backgroundColor: "#d7d7d7", paddingTop: "1px" }}>
			<StyledBox>
				<StyledText>{t("Footer.financing")}</StyledText>
				<StyledFooter />
			</StyledBox>
		</Box>
	);
};

export default AppFooter;
