import { useState, useEffect } from "react";

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({ innerWidth: undefined, innerHeight: undefined });

	useEffect(() => {
		const handleResize = () => { setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight }); };
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
};

export default useWindowSize;
