import styled from "styled-components";
import { Box } from "rebass/styled-components";
import { path } from "ramda";
import propTypes from "prop-types";

const StyledBox = styled(Box)`
	height: ${(props) => props.height};
	border-radius: 10px;
	width: ${(props) => (props.width || "400px")};
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	box-shadow: ${path(["theme", "shadows", "small"])};
  	-webkit-box-shadow: ${path(["theme", "shadows", "small"])};
  	justify-content: space-evenly;
  	background-color: ${path(["theme", "colors", "secondary"])};
  	padding: 20px;
`;

StyledBox.propTypes = { height: propTypes.string };

StyledBox.defaultProps = { height: "500px" };

export default StyledBox;
