const PRIMARY_COLOR = "#2E9E9C";
const SECONDARY_COLOR = "#FFFFFF";
const GREY_COLOR = "#F1F2F2";
const DANGER_COLOR = "#a82a2a";
const WARNING_COLOR = "#a66321";
const SUCCESS_COLOR = "#0a6640";
const DARK_BLUE_COLOR = "#0f456e";
const DARK_GREY_COLOR = "#585858";
const FUCHSIA_COLOR = "#FF00FF";
const GREY_ICON_COLOR = "#5c7080";

const buttonVariants = {
	primary: {
		color: `${SECONDARY_COLOR}!important`,
		backgroundColor: `${PRIMARY_COLOR}!important`,
		cursor: "pointer",
		border: "none",
	},
	secondary: {
		color: `${DARK_BLUE_COLOR}!important`,
		backgroundColor: `${SECONDARY_COLOR}!important`,
		border: `1px solid ${DARK_BLUE_COLOR}`,
		cursor: "pointer",
	},
	secondaryReverse: {
		color: `${SECONDARY_COLOR}!important`,
		backgroundColor: `${DARK_BLUE_COLOR}!important`,
		border: `1px solid ${DARK_BLUE_COLOR}`,
		cursor: "pointer",
	},
	transparent: {
		backgroundColor: "Transparent!important",
		color: `${DARK_GREY_COLOR}!important`,
		border: "none",
		cursor: "pointer",
	},
};

const theme = {
	breakpoints: ["600px", "1000px"],
	fontSizes: [10, 12, 14, 16, 18, 20, 24, 32, 48, 64],
	colors: {
		primary: PRIMARY_COLOR,
		secondary: SECONDARY_COLOR,
		grey: GREY_COLOR,
		darkBlue: DARK_BLUE_COLOR,
		danger: DANGER_COLOR,
		warning: WARNING_COLOR,
		success: SUCCESS_COLOR,
		darkGrey: DARK_GREY_COLOR,
		fuchsia: FUCHSIA_COLOR,
		greyIcon: GREY_ICON_COLOR,
	},
	space: [0, 4, 8, 16, 32, 64, 128, 256],
	fonts: {
		sans: "system-ui, sans-serif",
		mono: "Menlo, monospace",
	},
	shadows: {
		small: "0 0 4px rgba(0, 0, 0, .125)",
		large: "0 0 24px rgba(0, 0, 0, .125)",
	},
	displays: ["none", "block", "flex"],
	buttons: buttonVariants,
};

export default theme;
