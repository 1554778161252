import shortid from "shortid";
import { T, cond } from "ramda";
import Cookies from "js-cookie";

import { reducer } from "../lib/redux-helpers";
import { updateCookie } from "../lib/utilities";

const getInitialView = () => {
	const uiCookie = Cookies.get("ui");
	if (uiCookie) {
		const lng = JSON.parse(uiCookie).lng;
		if (!lng) {
			updateCookie("ui", { lng: "en-US" });
		}

		const view = JSON.parse(uiCookie).view;
		if (view) {
			return view;
		}
	}

	Cookies.set("ui", JSON.stringify({ lng: "en-US" }), { path: "/" });
	const authCookie = Cookies.get("auth");
	if (authCookie) {
		if (JSON.parse(authCookie).user.roles.includes("producer")) {
			updateCookie("ui", { view: "producer" });
			return "producer";
		}

		if (JSON.parse(authCookie).user.roles.includes("developer")) {
			updateCookie("ui", { view: "developer" });
			return "developer";
		}

		updateCookie("ui", { view: "owner" });
		return "owner";
	}

	return "";
};

const initView = getInitialView();

export const initialState = {
	errors: [],
	alert: { message: "", on: false },
	filters: { tags: [] },
	view: initView,
};

const addError = reducer("UI.ADD_ERROR", (state, { payload }) => ({
	...state,
	errors: [...state.errors, { ...payload, id: shortid.generate() }],
}));

const removeError = reducer("UI.REMOVE_ERROR", (state, { payload: id }) => ({
	...state,
	errors: state.errors.filter((error) => error.id !== id),
}));

const triggerAlert = reducer(
	"UI.TRIGGER_ALERT",
	(state, { payload: message }) => ({
		...state,
		alert: { on: true, message },
	}),
);

const disableAlert = reducer("UI.DISABLE_ALERT", (state) => ({
	...state,
	alert: { on: false, message: "" },
}));

const changeView = reducer("UI.CHANGE_VIEW", (state, { payload: view }) => ({
	...state,
	view,
}));

const defaultCase = [T, (state) => state || initialState];

export default cond([
	addError,
	removeError,
	triggerAlert,
	disableAlert,
	changeView,
	defaultCase,
]);
