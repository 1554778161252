const localesUS = {
	AppHeader: {
		profile: { text: "Profile", tooltip: "Edit Profile" },
		role: { text: "Role", tooltip: "Change Role" },
		owner: "View as Device Owner",
		developer: "View as Developer",
		producer: "View as Producer",
		platform: { text: "Platform", tooltip: "Go to Platform" },
		logout: "Log out",
	},
	SideNavigation: {
		apps: "Applications",
		myapps: "My applications",
		addapp: "Upload app",
		fastdeploy: "Test application",
		instructions: "Instructions",
		elsa: "My devices",
		events: "Events Calendar",
		devices: "Devices",
		contacts: "Contacts",
		release: "Software release",
		types: "Device types",
		modules: "Device modules",
	},
	SignUpScreen: {
		header: "Sign Up to Sities",
		email: "Email",
		password: "Password",
		confirm: "Confirm password",
		developer: "Developer",
		owner: "Device owner",
		producer: "Producer",
		signup: "Sign up",
		signin: "Sign in",
		registered: "Already have an account?",
	},
	SignInScreen: {
		header: "Sign In to Sities",
		email: "Email",
		password: "Password",
		signup: "Sign up",
		signin: "Sign in",
		forgot: "Forgot password?",
		forgotlink: "Click here",
		newuser: "Don't have an account?",
	},
	ForgotPasswordScreen: {
		header: "Trouble Logging In",
		description:
      "Enter your email and we'll send you a link to get back into your account.",
		email: "Email",
		send: "Send Link",
		create: "Create New Account",
	},
	ChangePasswordScreen: {
		header: "Change your password",
		password: "Password",
		confirm: "Confirm password",
		change: "Change password",
	},
	Fetcher: {
		error: "Failed to load data.",
		offlineError: "Device appears to be offline.",
	},
	Preferences: {
		header: "Profile",
		name: "Name",
		cancel: "Cancel",
		surname: "Surname",
		displayName: "Display name",
		email: "Email",
		password: "New password",
		confirmPassword: "Confirm new password",
		showPassword: "Show password",
		hidePassword: "Hide password",
		language: "Language",
		edit: "Edit",
		save: "Save",
		saveSuccess: "Profile has been updated successfully.",
		account: "Delete account",
		myAccount: "Delete my account",
		delete: "Delete",
		deleteUser: "Are you sure you want to delete your account?",
		deleteSuccess: "You account was successfully deleted.",
		export: "Export profile",
		myexport: "Export my profile",
		exportMessage: "Your profile was exported successfully. You will soon receive an e-mail.",
	},
	Validations: {
		email: {
			invalid: "Invalid e-mail.",
			required: "Email is required.",
			gmail: "Email must end with @gmail.com .",
		},
		password: {
			required: "Password is required.",
			min: "Password must be at least {{min}} characters.",
			confirm: "Passwords don't match.",
		},
		app: {
			required: "App tar.gz is required.",
			type: "Only tar.gz files are supported.",
			size: "Maximum app size is {{size}}MBs.",
		},
		deviceName: { required: "Device name is required." },
		serialNumber: { required: "Serial number is required." },
		appName: {
			required: "App name is required.",
			format: "App name can contain only latin characters (no capital), numbers and the following symbols ( _ , - ).",
		},
		appVersion: { required: "App version is required." },
		description: { required: "App description is required." },
		privacyPolicyLink: { required: "Privacy policy link is required.", format: "Link is not a valid URL." },
		icon: { required: "App icon is required." },
		screenshots: { required: "App screenshots are required." },
		field: {
			required: "This field is required.",
			min: "Number must be greater than or equal to 0.",
			number: "This field must be a number.",
			date: "This field must be a date.",
			time: "This field must contain time.",
			array: "This field must contain at least one element.",
			string: "This field must be a string.",
		},
		branchName: { required: "Branch / Tag name is required." },
		version: { required: "Version is required." },
		name: { required: "Νame is required." },
		releaseDescription: { required: "Description is required." },
		deviceDescription: { required: "Description is required." },
		macAddress: { required: "MAC address is required." },
		username: { required: "Broker username is required." },
		ehealthPassUsername: { required: "eHealthPass username is required." },
		devicePassword: { required: "Broker password is required." },
		script: {
			required: "File .py is required.",
			type: "Only .py files are supported.",
			size: "Maximum file size is {{size}}MB.",
		},
		moduleName: { required: "Module unix name is required." },
		moduleDisplayName: { required: "Module name is required." },
		typeName: { required: "Type name is required." },
		typeHost: { required: "Host is required." },
		typePort: { required: "Port is required." },
		backendUsername: { required: "Backend username is required." },
		backendPassword: { required: "Backend password is required." },
		patientName: { required: "User name is required." },
		contactName: { required: "Contact name is required." },
		contactEmail: { required: "Contact email is required." },
		phone: {
			required: "Contact phone number is required.",
			number: "This field must contain only numbers.",
		},
	},
	BackNav: {
		title: "Back",
		alertConfirm: "Yes",
		alertCancel: "No",
	},
	Toasters: {
		reset: "Reset email was sent.",
		change: "Your password was changed.",
		install: "The application is being installed on your device.",
		uninstall: "The application is being uninstalled from your device.",
		upgrade: "Module is being upgraded.",
		createRelease: "Software release was created.",
		editRelease: "Software release was updated.",
		deleteRelease: "Software release was deleted.",
		emptyUpgrades: "You must select modules.",
		releaseUpgrade: "Device is being upgraded.",
		noReleases: "There are no available releases.",
		createDevice: "Device was created.",
		editDevice: "Device was updated.",
		deleteDevice: "Device was deleted.",
		logs: "Enable logs",
		enableLogs: "Logs are enabled.",
		disableLogs: "Logs are disabled.",
		fastDeploy: "Application was successfully deployed.",
		createModule: "Module was created.",
		createType: "Device type was created.",
		editType: "Device type was updated.",
		deleteType: "Device type was deleted.",
		addElder: "User was created.",
		addContact: "Contact was created.",
		createEvent: "Event scheduled successfully",
		createEventFailed: "Event scheduling failed",
		signInFail: "Sign in failed. Please enter valid credentials.",
	},
	SuggestInput: { noResults: "No results" },
	SearchBar: { placeholder: "Search..." },
	ApplicationSuggestions: {
		all: "All applications",
		suggestions: "Suggestions for you",
		new: "New applications",
		view: "View all",
	},
	ApplicationPreview: {
		view: "View",
		rating: "rating",
		ratings: "ratings",
	},
	Application: {
		category: "App category:",
		name: "App name:",
		developer: "Developer:",
		rating: "rating",
		ratings: "ratings",
		install: "Install",
		noDevices: "There are no compatible devices with this app",
		update: "Update",
		uninstall: "Uninstall",
		description: "Description",
		additional: "Additional info",
		created: "Created",
		updated: "Updated",
		installs: "Current installs",
		downloads: "Total downloads",
		size: "Size",
		version: "Version",
		type: "Device type",
		compatibility: "Device Version",
		edit: "Edit",
		download: "Download",
		delete: "Delete",
		deleteAlert: "Are you sure you want to delete this application?",
		alertConfirm: "Yes",
		alertCancel: "No",
		deleteSuccess: "Application was successfully deleted.",
		deleteFail: "Application was not permanently deleted, but it was made unavailable for installation.",
		selectDevice: "Select device",
		apply: "Apply",
		cancel: "Cancel",
		showAdvanced: "Show advanced parameters",
		hideAdvanced: "Hide advanced parameters",
		initParams: "Initial parameters",
		arrayElements: "Separate elements by \",\"",
		infoLink: "Click here for more information",
	},
	UploadApplication: {
		header: "Upload new app",
		nameLabel: "Name",
		namePlaceholder: "New app",
		fileLabel: "Tar.gz file",
		filePlaceholder: "Select tar.gz",
		fileButtonPlaceholder: "Search",
		descriptionLabel: "Description",
		descriptionPlaceholder: "This app...",
		iconLabel: "Icon",
		iconPlaceholder: "Select icon",
		screenshotsLabel: "Screenshots",
		screenshotsPlaceholder: "Select screenshots",
		upload: "Upload",
		appVersionLabel: "Application version",
		appVersionPlaceholder: "1.0.0",
		typeLabel: "Device type",
		deviceVersionLabel: "Device version",
		privacyPolicyLabel: "Privacy policy link",
		privacyPolicyPlaceholder: "https://sities-project.gr/",
		categoryLabel: "Category",
		utilities: "Utilities",
		learning: "Learning",
		music: "Music",
		entertainment: "Entertainment",
		alertMessage: "Are you sure you want to leave? All your progress will be lost.",
		uploadSuccess: "Application has been uploaded successfully.",
		updateSuccess: "Application has been updated successfully.",
		tarInfo: {
			header: "Tar.gz file should contain at least 3 files as described below:",
			info: "The app.info file will list the following parameters in a yaml format:\n- version\n- elsa version\n- description\n- tags\n",
			commands: "The voice-commands.txt file will contain the voice commands with which the user can start the application. By default the application will start if the user says its name.",
			init: "The init.conf file will list the initial parameters in a yaml format, specifying the name and type and placeholder of each parameter.\n",
			exec: "The exec.conf file contains the execution parameters of the application. These are:\n- start_on_boot: <bool>. If declared true, the application will start on boot.\n",
			important: "*Important: To compress the required files use the command `tar -zcvf file.tar.gz /path/to/directory`",
			download: "Download sample file",
			required: "(required)",
			close: "Close",
		},
	},
	MyElsa: {
		newDevice: "Add new device",
		status: "Status",
		version: "Version",
		online: "Online",
		offline: "Offline",
		addSuccess: "Device successfully added.",
		deleteDevice: "Are you sure you want to delete this device?",
		selectModule: "Select module",
		selectType: "Select type",
		selectName: "Branch / Tag name",
		selectRelease: "Select release",
		view: "View",
		edit: "Edit",
		delete: "Delete",
		cancel: "Cancel",
		moduleUpgrade: "Upgrade module",
		releaseUpgrade: "Upgrade release",
		reboot: "Reboot",
		shutdown: "Shutdown",
		rebootSuccess: "Device is rebooting.",
		shutdownSuccess: "Device is shutting down.",
		deleteSuccess: "Device was successfully deleted.",
	},
	MyElsaPopup: {
		name: "Device name",
		serialNumber: "Serial Number",
		add: "Add",
		cancel: "Cancel",
	},
	MyElsaId: {
		deviceInfo: "Device Information",
		installedApps: "Installed Apps",
		name: "Device Name",
		owner: "Device Owner",
		serial: "Serial Number",
		volume: "Device Volume",
		cpu: "Device CPU",
		memory: "Device Memory",
		disk: "Device Disk",
		app: "Activated Apps",
		activate: "Activation",
		deactivate: "Deactivation",
		gmail: "Gmail",
		ssh: "SSH",
		logs: "Logs",
		calendar: "Calendar",
		listenHeader: "Listen Results",
		noListenResults: "There are no listen results",
	},
	MyElsaIdPopup: {
		startHeader: "You have selected the activation of:",
		stopHeader: "You have selected the deactivation of:",
		startMessage: "Are you sure you want to activate this application?",
		stopMessage: "Are you sure you want to deactivate this application?",
		activation: "Activation",
		deactivation: "Deactivation",
		accept: "Accept",
		cancel: "Cancel",
		updateApp: "There is a new version of this app",
		upgradeToUpdateApp: "There is a new version of this app. Device software update is required",
		uninstall: "Uninstall",
		uninstallText: "Are you sure you want to uninstall this application?",
		uninstallMessage: "Application in being uninstalled from your device.",
		update: "Change device information",
		updateSuccess: "Device was successfully updated.",
		updateOwner: "Change device owner",
		updateOwnerSuccess: "Device owner was successfully updated.",
		startSuccess: "App is being activated.",
		stopSuccess: "App is being deactivated.",
		criticalError: "Critical error",
		message: "Message",
	},
	SoftwareRelease: {
		createTitle: "Create new software release",
		editTitle: "Edit software release",
		deleteTitle: "Delete software release",
		version: "Version",
		description: "Description",
		filePlaceholder: "Select .py file",
		fileButtonPlaceholder: "Search",
		create: "Create",
		edit: "Edit",
		delete: "Delete",
		showModules: "Modules...",
		modules: "Modules",
		module: "Module",
		type: "Type",
		name: "Name",
		apply: "Apply",
		cancel: "Cancel",
	},
	DeviceSettings: {
		deviceSettings: "Device settings",
		createDevice: "Create device",
		name: "Device name",
		serial: "Serial number",
		description: "Description",
		mac: "MAC address",
		version: "Version",
		brokerUsername: "Broker username",
		brokerPassword: "Broker password",
		create: "Create",
		editDevice: "Edit device",
		edit: "Edit",
		deleteDevice: "Delete device",
		delete: "Delete",
	},
	FastDeploy: {
		title: "Test application",
		appName: "App name",
		filePlaceholder: "Select tar.gz",
		fileButtonPlaceholder: "Search",
		fastDeploy: "Deploy",
	},
	Modules: {
		title: "Module Management",
		module: "Module",
		name: "Unix Name",
		heartbeat: "Heartbeat",
		logs: "Logs",
		release: "Release",
		actions: "Actions",
		add: "Add module",
		createModule: "Create module",
		selectName: "Module unix name",
		selectDisplayName: "Module name",
		create: "Create",
		editSuccess: "Module has been updated successfully.",
		deleteModule: "Are you sure you want to delete this module?",
		delete: "Delete",
		deleteSuccess: "Module was successfully deleted.",
		cancel: "Cancel",
	},
	Types: {
		createTitle: "Create new device type",
		editTitle: "Edit device type",
		deleteTitle: "Delete device type",
		name: "Name",
		host: "Host",
		port: "Port",
		backendUsername: "Backend username",
		backendPassword: "Backend password",
		create: "Create",
		edit: "Edit",
		delete: "Delete",
		showModules: "Modules...",
		modules: "Modules",
		module: "Module",
		type: "Type",
		apply: "Apply",
		cancel: "Cancel",
	},
	Patients: {
		title: "Elsa users",
		name: "Name",
		ehealthPassUsername: "eHealthpass username",
		device: "Device",
		actions: "Actions",
		addElder: "Add user",
		deletePatient: "Are you sure you want to delete this user?",
		add: "Add",
		selectDevice: "Select device",
		select: "Select",
		editSuccess: "User has been updated successfully.",
		editFail: "User information is incorrect.",
		delete: "Delete",
		cancel: "Cancel",
		deleteSuccess: "User was successfully deleted.",
	},
	Contacts: {
		title: "Contacts",
		name: "Νame",
		email: "Εmail",
		phone: "Phone number",
		actions: "Actions",
		addContact: "Add contact",
		add: "Add",
		cancel: "Cancel",
		editSuccess: "Contact has been updated successfully.",
		editFail: "Contact information is incorrect.",
		deleteContact: "Are you sure you want to delete this contact?",
		deleteSuccess: "Contact was successfully deleted.",
		delete: "Delete",

	},
	Instructions: {
		title: "Application developement instructions",
	},
	Calendar: {
		addCalendarEvent: "Add calendar event",
		never: "Do not repeat",
		day: "Repeat per Day",
		week: "Repeat per Week",
		biweek: "Repeat per 2 Weeks",
		month: "Repeat per Month",
		year: "Repeat per Year",
		until: "Until :",
		startOfDayEvent: "Start of day event",
		selectApp: "Select app",
		startDateRequired: "Start date is required",
		startTimeRequired: "Start time is required",
		repeatUntilRequired: "End date is required",
		upcomingEvents: "Upcoming events",
		deleteEvent: "Are you sure you want to delete this event?",
		deleteEventSuccessful: "Event was successfully deleted.",
		deleteEventFail: "There was some problem during the deletion.",
		delete: "Delete",
		cancel: "Cancel",
		noEvents: "There are no upcoming events!",
		start: "Start",
		description: "Description",
		timeDescription: "You can select any time you like but the applications will start on :00, :15, :30 or :45.",
	},
	Footer: { financing: "The development of this website is supported by the SITIES project - Third Age Support System with Smart Devices, \nimplemented as part of the first cycle of Research-Create-Innovate action with code T1EDK-02347." },
};

export default localesUS;
