import { useEffect, useState } from "react";
import { path } from "ramda";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { MenuItem } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Flex } from "rebass/styled-components";

import getSuggestions from "../api/search";
import { sanitize } from "../lib/utilities";

import { Highlighter } from "./common/ui";
import { NoResults } from "./common/form/suggest-input";

const icons = {
	entertainment: "glass",
	utilities: "wrench",
	learning: "book",
	music: "music",
};

const renderItem = (item, { handleClick, query }) => (
	<MenuItem
		key={item.id}
		icon={icons[item.category]}
		text={(
			<Highlighter
				text={`${item.name}`}
				highlight={sanitize(query)}
			/>
		)}
		onClick={handleClick}
	/>
);

const SearchBar = ({ history: { push: pushHistory } }) => {
	const { t } = useTranslation();
	const [searchText, setSearchText] = useState("");
	const [data, setData] = useState([]);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			try {
				const { suggestions } = await getSuggestions({ name: searchText });
				if (isMounted) {
					setData(suggestions);
				}
			} catch {
				// do nothing
			}
		})();

		return () => { isMounted = false; };
	}, [searchText]);

	return (
		<Flex width={4 / 10}>
			<Suggest
				fill
				resetOnSelect
				popoverProps={{ minimal: true, className: "search" }}
				items={data}
				itemRenderer={renderItem}
				selectedItem={null}
				inputValueRenderer={(item) => `${item.name}`}
				noResults={<NoResults />}
				inputProps={{
					name: "search",
					placeholder: t("SearchBar.placeholder"),
					value: searchText,
					autoComplete: "off",
				}}
				onItemSelect={(item) => pushHistory(item.id)}
				onQueryChange={(query) => setSearchText(sanitize(query))}
			/>
		</Flex>
	);
};

const mapState = (state) => ({
	userId: path(["auth", "user", "id"], state),
	view: path(["ui", "view"], state),
});

SearchBar.propTypes = { history: propTypes.objectOf(propTypes.any).isRequired };

export default connect(mapState)(withRouter(SearchBar));
