import { path } from "ramda";
import propTypes from "prop-types";
import styled from "styled-components";
import { layout } from "styled-system";

import sitiesEeris from "../../../assets/Sities_eeris.png";

const StyledEeris = styled.img.attrs(() => ({
	src: sitiesEeris,
	alt: "eeris icon",
	width: [80, 80],
	height: [80, 80],
}))`${layout}
	border-radius: 50%;
	border: 15px solid ${(props) => (props.status === "online" ? path(["theme", "colors", "danger"]) : "#B7B7B7")};
	background-color: ${(props) => (props.status === "online" ? path(["theme", "colors", "danger"]) : "#B7B7B7")};
	margin-left: auto;
	margin-right: auto;
	margin-top: ${(props) => (props.status === "add" ? "21px" : "0px")};
	margin-bottom: 5px;
	position: relative;
`;

StyledEeris.propTypes = { status: propTypes.string.isRequired };

export default StyledEeris;
