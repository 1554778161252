const localesGR = {
	AppHeader: {
		profile: { text: "Προφίλ", tooltip: "Επεξεργασία Προφίλ" },
		role: { text: "Ρόλος", tooltip: "Αλλαγή ρόλου" },
		owner: "Προβολή ως Ιδιοκτήτη συσκευής",
		developer: "Προβολή ως Προγραμματιστή",
		producer: "Προβολή ως Διαχειριστή",
		platform: { text: "Πλατφόρμα", tooltip: "Μετάβαση σε Πλατφόρμα" },
		logout: "Αποσύνδεση",
	},
	SideNavigation: {
		apps: "Εφαρμογές",
		myapps: "Οι εφαρμογές μου",
		addapp: "Νέα εφαρμογή",
		fastdeploy: "Έλεγχος εφαρμογής",
		instructions: "Οδηγίες",
		elsa: "Οι συσκευές μου",
		events: "Ημερολόγιo",
		devices: "Συσκευές",
		contacts: "Επαφές",
		release: "Έκδοση λογισμικού",
		types: "Τύποι συσκευών",
		modules: "Modules συσκευών",
	},
	SignUpScreen: {
		header: "Εγγραφείτε στο ΣΥΤΗΕΣ",
		email: "Ηλεκτρονική διεύθυνση",
		password: "Κωδικός",
		confirm: "Επιβεβαίωση κωδικού",
		developer: "Προγραμματιστής",
		owner: "Ιδιοκτήτης συσκευής",
		producer: "Διαχειριστής",
		signup: "Εγγραφή",
		signin: "Σύνδεση",
		registered: "Έχετε ήδη λογαριασμό;",
	},
	SignInScreen: {
		header: "Συνδεθείτε στο ΣΥΤΗΕΣ",
		email: "Ηλεκτρονική διεύθυνση",
		password: "Κωδικός",
		signup: "Εγγραφή",
		signin: "Σύνδεση",
		forgot: "Ξεχάσατε το κωδικό σας;",
		forgotlink: "Πατήστε εδώ",
		newuser: "Δεν έχετε λογαριασμό;",
	},
	ForgotPasswordScreen: {
		header: "Δεν μπορείτε να συνδεθείτε;",
		description: "Πληκτρολογήστε το email σας και θα σας στείλουμε ένα σύνδεσμο για να αποκτήσετε ξανά πρόσβαση στο λογαριασμό σας.",
		email: "Ηλεκτρονική διεύθυνση",
		send: "Αποστολή Συνδέσμου",
		create: "Δημιουργία νέου λογαριασμού",
	},
	ChangePasswordScreen: {
		header: "Αλλάξτε το κωδικό σας",
		password: "Κωδικός",
		confirm: "Επιβεβαίωση κωδικού",
		change: "Αλλαγή κωδικού",
	},
	Fetcher: {
		error: "Αποτυχία λήψης δεδομένων",
		offlineError: "Η συσκευή φαίνεται ανενεργή.",
	},
	Preferences: {
		header: "Προφίλ",
		name: "Όνομα",
		surname: "Επώνυμο",
		displayName: "Εμφανιζόμενο όνομα",
		cancel: "Ακύρωση",
		email: "Ηλεκτρονική διεύθυνση",
		password: "Νέος κωδικός",
		confirmPassword: "Επιβεβαίωση νέου κωδικού",
		showPassword: "Εμφάνιση κωδικού",
		hidePassword: "Απόκρυψη κωδικού",
		language: "Γλώσσα",
		edit: "Επεξεργασία",
		save: "Αποθήκευση",
		saveSuccess: "Το προφίλ ενημερώθηκε επιτυχώς.",
		account: "Διαγραφή λογαριασμού",
		myAccount: "Διαγραφή του λογαριασμού μου",
		delete: "Διαγραφή",
		deleteUser: "Είστε σίγουρος ότι θέλετε να διαγράψετε το λογαριασμό σας;",
		deleteSuccess: "Ο λογαριασμός σας διαγράφηκε επιτυχώς.",
		export: "Εξαγωγή προφίλ",
		myexport: "Εξαγωγή του προφίλ μου",
		exportMessage: "Η εξαγωγή του προφίλ σας πραγματοποιήθηκε επιτυχώς. Σύντομα θα λάβετε σχετικό e-mail.",
	},
	Validations: {
		email: {
			invalid: "Μη έγκυρη διεύθυνση e-mail.",
			required: "Η διεύθυνση e-mail είναι απαραίτητη.",
			gmail: "Η διέυθυνση πρέπει να λήγει σε @gmail.com .",
		},
		password: {
			required: "Ο κωδικός είναι απαραίτητος.",
			min: "Ο κωδικός πρέπει να περιέχει τουλάχιστον {{min}} χαρακτήρες.",
			confirm: "Οι κωδικοί πρέπει να ταιριάζουν.",
		},
		app: {
			required: "Το tar.gz της εφαρμογής είναι απαραίτητο.",
			type: "Μόνο αρχεία tar.gz υποστηρίζονται.",
			size: "Μέγιστο μέγεθος εφαρμογής {{size}}MBs.",
		},
		deviceName: { required: "Το όνομα της συσκευής είναι απαραίτητο." },
		serialNumber: { required: "Ο σειριακός αριθμός είναι απαραίτητος." },
		appName: {
			required: "Το όνομα της εφαρμογής είναι απαραίτητο.",
			format: "Το όνομα της εφαρμογής μπορεί να περιέχει μόνο λατινικούς χαρακτήρες (όχι κεφαλαία), αριθμούς και τα ακόλουθα σύμβολα ( _ , - ).",
		},
		appVersion: { required: "Η έκδοση της εφαρμογής είναι απαραίτητη." },
		description: { required: "Η περιγραφή της εφαρμογής είναι απαραίτητη." },
		privacyPolicyLink: { required: "O σύνδεσμος πολιτικής απορρήτου είναι απαραίτητος.", format: "Ο σύνδεσμος δεν είναι έγκυρο URL." },
		icon: { required: "Το εικονίδιο της εφαρμογής είναι απαραίτητο." },
		screenshots: { required: "Τα στιγμιότυπα της εφαρμογής είναι απαραίτητα." },
		field: {
			required: "Το πεδίο είναι απαραίτητο.",
			min: "Ο αριθμός πρέπει να είναι μικρότερος ή ίσος του 0.",
			number: "Το πεδίο πρέπει να περιέχει αριθμό.",
			date: "Το πεδίο πρέπει να περιέχει ημερομηνία.",
			time: "Το πεδίο πρέπει να περιέχει ώρα.",
			array: "Το πεδίο πρέπει να περιέχει τουλάχιστον 1 στοιχείο.",
			string: "Το πεδίο πρέπει να περιέχει χαρακτήρες.",
		},
		branchName: { required: "Το όνομα του branch / tag είναι απαραίτητο." },
		version: { required: "Η έκδοση είναι απαραίτητη." },
		name: { required: "Το όνομα είναι απαραίτητο." },
		releaseDescription: { required: "Η περιγραφή είναι απαραίτητη." },
		deviceDescription: { required: "Η περιγραφή είναι απαραίτητη." },
		macAddress: { required: "Η MAC διεύθυνση είναι απαραίτητη." },
		username: { required: "Το όνομα broker είναι απαραίτητο." },
		ehealthPassUsername: { required: "Το όνομα eHealthPass είναι απαραίτητο." },
		devicePassword: { required: "Ο κωδικός broker είναι απαραίτητος." },
		script: {
			required: "Το αρχείο .py είναι απαραίτητο.",
			type: "Μόνο αρχεία .py υποστηρίζονται.",
			size: "Μέγιστο μέγεθος αρχείου {{size}}MB.",
		},
		moduleName: { required: "Το unix όνομα είναι απαραίτητο." },
		moduleDisplayName: { required: "Το όνομα είναι απαραίτητο." },
		typeName: { required: "Το όνομα είναι απαραίτητο." },
		typeHost: { required: "Το πεδίο host είναι απαραίτητο." },
		typePort: { required: "Το πεδίο port είναι απαραίτητο." },
		backendUsername: { required: "Το όνομα backend είναι απαραίτητο." },
		backendPassword: { required: "Ο κωδικός backend είναι απαραίτητος." },
		patientName: { required: "Το όνομα χρήστη είναι απαραίτητο." },
		contactName: { required: "Το όνομα επαφής είναι απαραίτητο." },
		contactEmail: { required: "Το email επαφής είναι απαραίτητο." },
		phone: {
			required: "Ο αριθμός τηλεφώνου επαφής είναι απαραίτητος.",
			number: "Το πεδίο πρέπει να περιέχει μόνο αριθμούς.",
		},
	},
	BackNav: {
		title: "Πίσω",
		alertConfirm: "Ναι",
		alertCancel: "Όχι",
	},
	Toasters: {
		reset: "Το email επαναφοράς κωδικού στάλθηκε.",
		change: "Ο κωδικός σας άλλαξε.",
		install: "Η εφαρμογή εγκαθίσταται στην Έλσα σας.",
		uninstall: "Η εφαρμογή απεγκαθίσταται από την Έλσα σας.",
		upgrade: "Το module αναβαθμίζεται.",
		createRelease: "Η έκδοση λογισμικού δημιουργήθηκε.",
		editRelease: "Η έκδοση λογισμικού ενημερώθηκε.",
		deleteRelease: "Η έκδοση λογισμικού διαγράφτηκε.",
		emptyUpgrades: "Πρέπει να επιλέξετε modules.",
		releaseUpgrade: "Η συσκευή αναβαθμίζεται.",
		noReleases: "Δεν υπάρχουν διαθέσιμες εκδόσεις.",
		createDevice: "Η συσκευή δημιουργήθηκε.",
		editDevice: "Η συσκευή ενημερώθηκε.",
		deleteDevice: "Η συσκευή διαγράφτηκε.",
		logs: "Ενεργοποίηση logs",
		enableLogs: "Τα logs ενεργοποιήθηκαν.",
		disableLogs: "Τα logs απενεργοποιήθηκαν.",
		fastDeploy: "Η εφαρμογή αναπτύχθηκε επιτυχώς.",
		createModule: "To module δημιουργήθηκε.",
		createType: "Ο τύπος συσκευής δημιουργήθηκε.",
		editType: "Ο τύπος συσκευής ενημερώθηκε.",
		deleteType: "Ο τύπος συσκευής διαγράφτηκε.",
		addElder: "Ο χρήστης δημιουργήθηκε.",
		addContact: "Η επαφή δημιουργήθηκε.",
		createEvent: "Η εφαρμογή προστέθηκε στο ημερολόγιο",
		createEventFailed: "Ο προγραμματισμός της εφαρμογής απέτυχε",
		signInFail: "Η σύνδεση απέτυχε. Παρακαλώ εισάγετε έγκυρα στοιχεία.",
	},
	SuggestInput: { noResults: "Δεν βρέθηκαν αποτελέσματα." },
	SearchBar: { placeholder: "Αναζήτηση..." },
	ApplicationSuggestions: {
		all: "Όλες οι εφαρμογές",
		suggestions: "Προτάσεις για εσάς",
		new: "Νέες εφαρμογές",
		view: "Προβολή όλων",
	},
	ApplicationPreview: {
		view: "Προβολή",
		rating: "κριτική",
		ratings: "κριτικές",
	},
	Application: {
		category: "Κατηγορία εφαρμογής:",
		name: "Όνομα εφαρμογής:",
		developer: "Προγραμματιστής:",
		rating: "κριτική",
		ratings: "κριτικές",
		install: "Εγκατάσταση",
		noDevices: "Δεν υπάρχουν συμβατές συσκευές με αυτήν την εφαρμογή",
		update: "Ενημέρωση",
		uninstall: "Απεγκατάσταση",
		description: "Περιγραφή",
		additional: "Επιπλέον πληροφορίες",
		created: "Δημιουργήθηκε",
		updated: "Τροποποιήθηκε",
		installs: "Τρέχουσες εγκαταστάσεις",
		downloads: "Συνολικές λήψεις",
		size: "Μέγεθος",
		version: "Έκδοση",
		type: "Τύπος συσκευής",
		compatibility: "Έκδοση συσκευής",
		edit: "Επεξεργασία",
		download: "Λήψη",
		delete: "Διαγραφή",
		deleteAlert: "Είστε σίγουρος ότι θέλετε να διαγράψετε αυτή την εφαρμογή;",
		alertConfirm: "Ναι",
		alertCancel: "Όχι",
		deleteSuccess: "Η εφαρμογή διαγράφηκε επιτυχώς.",
		deleteFail: "Η εφαρμογή δεν διαγράφηκε οριστικά, αλλά έγινε μη διαθέσιμη για εγκατάσταση.",
		selectDevice: "Επιλέξτε συσκευή",
		apply: "Αποδοχή",
		cancel: "Ακύρωση",
		showAdvanced: "Προβολή παραμέτρων για προχωρημένους",
		hideAdvanced: "Απόκρυψη παραμέτρων για προχωρημένους",
		initParams: "Παράμετροι αρχικοποίησης",
		arrayElements: "Διαχωρίστε τα στοιχεία με \",\"",
		infoLink: "Πατήστε εδώ για περισσότερες πληροφορίες",
	},
	UploadApplication: {
		header: "Μεταφόρτωση νέας εφαρμογής",
		nameLabel: "Όνομα",
		namePlaceholder: "Νέα εφαρμογή",
		fileLabel: "Αρχείο Tar.gz",
		filePlaceholder: "Επιλέξετε tar.gz",
		fileButtonPlaceholder: "Αναζήτηση",
		descriptionLabel: "Περιγραφή",
		descriptionPlaceholder: "Αυτή η εφαρμογή...",
		iconLabel: "Εικονίδιο",
		iconPlaceholder: "Επιλέξετε εικονίδιο",
		screenshotsLabel: "Στιγμιότυπα",
		screenshotsPlaceholder: "Επιλέξετε στιγμιότυπα",
		upload: "Μεταφόρτωση",
		appVersionLabel: "Έκδοση εφαρμογής",
		appVersionPlaceholder: "1.0.0",
		typeLabel: "Τύπος συσκευής",
		deviceVersionLabel: "Έκδοση συσκευής",
		privacyPolicyLabel: "Σύνδεσμος πολιτικής απορρήτου",
		privacyPolicyPlaceholder: "https://sities-project.gr/",
		categoryLabel: "Κατηγορία",
		utilities: "Λειτουργική",
		learning: "Εκπαιδευτική",
		entertainment: "Ψυχαγωγία",
		music: "Μουσική",
		alertMessage: "Είστε σίγουρος ότι θέλετε να αποχωρήσετε; Όλη η πρόοδος σας θα χαθεί.",
		uploadSuccess: "Η εφαρμογή μεταφορτώθηκε επιτυχώς.",
		updateSuccess: "Η εφαρμογή ενημερώθηκε επιτυχως.",
		tarInfo: {
			header: "Το αρχείο tar.gz πρέπει να περιέχει τουλάχιστον 3 αρχεία όπως περιγράφονται παρακάτω:",
			info: "Το αρχείο app.info θα περιλαμβάνει τις ακόλουθες παραμέτρους σε μορφή αρχείου yaml:\n- version\n- elsa version\n- description\n- tags\n",
			commands: "Το αρχείο voice-commands.txt θα περιλαμβάνει τις φωνητικές εντολές με τις οποίες ο χρήστης θα μπορεί να εκκινεί την εφαρμογή. Από προεπιλογή η εφαρμογή θα ξεκινάει εάν ο χρήστης πει το όνομα της.",
			init: "Το αρχείο init.conf θα περιλαμβάνει τις παραμέτρους αρχικοποίησης σε μορφή αρχείου yaml, προσδιορίζοντας το όνομα και τον τύπο και την επεξήγηση κάθε παραμέτρου.\n",
			exec: "Το αρχείο exec.conf θα περιλαμβάνει παραμέτρους που ορίζουν την εκτέλεση της εφαρμογής σε μορφή yaml. Οι παράμετροι που μπορούν να οριστούν είναι:\n- start_on_boot: <bool>. Εάν η παράμετρος είναι true, η εφαρμογή θα εκκινήσει με την εκκίνηση της συσκευής ΕΛΣΑ.\n",
			important: "*Σημαντικό: Για να συμπιέσετε αρχεία χρησιμοποιείστε την εντολή `tar -zcvf file.tar.gz /path/to/directory`",
			download: "Κατεβάστε πρότυπο αρχείο",
			required: "(απαραίτητο)",
			close: "Κλείσιμο",
		},
	},
	MyElsa: {
		newDevice: "Προσθέστε νέα συσκευή",
		status: "Κατάσταση",
		version: "Έκδοση",
		online: "Ενεργή",
		offline: "Ανενεργή",
		addSuccess: "Η συσκευή προστέθηκε επιτυχώς.",
		deleteDevice: "Είστε σίγουρος ότι θέλετε να διαγράψετε αυτήν τη συσκευή;",
		selectModule: "Επιλέξτε module",
		selectType: "Επιλέξτε τύπο",
		selectName: "Όνομα branch / tag",
		selectRelease: "Επιλέξτε έκδοση",
		view: "Προβολή",
		edit: "Επεξεργασία",
		delete: "Διαγραφή",
		cancel: "Ακύρωση",
		moduleUpgrade: "Αναβάθμιση module",
		releaseUpgrade: "Αναβάθμιση έκδοσης",
		reboot: "Επανεκκίνηση",
		shutdown: "Απενεργοποίηση",
		rebootSuccess: "Η συσκευή επανεκκινείται.",
		shutdownSuccess: "Η συσκευή απενεργοποιείται.",
		deleteSuccess: "Η συσκευή διαγράφηκε επιτυχώς.",
	},
	MyElsaPopup: {
		name: "Όνομα συσκευής",
		serialNumber: "Σειριακός αριθμός",
		add: "Προσθήκη",
		cancel: "Ακύρωση",
	},
	MyElsaId: {
		deviceInfo: "Πληροφορίες Συσκευής",
		installedApps: "Εγκατεστημένες Εφαρμογές",
		name: "Όνομα συσκευής",
		owner: "Ιδιοκτήτης συσκευής",
		serial: "Σειριακός αριθμός",
		volume: "Ένταση συσκευής",
		cpu: "CPU συσκευής",
		memory: "Μνήμη συσκευής",
		disk: "Δίσκος συσκευής",
		app: "Ενεργές εφαρμογές",
		activate: "Ενεργοποίηση",
		deactivate: "Απενεργοποίηση",
		gmail: "Διεύθυνση gmail",
		ssh: "SSH",
		logs: "Logs",
		calendar: "Ημερολόγιο",
		listenHeader: "Αποτελέσματα Ηχογράφησης",
		noListenResults: "Δεν υπάρχουν δεδομένα ηχογράφησης",
	},
	MyElsaIdPopup: {
		startHeader: "Επιλέξατε ενεργοποίηση της εφαρμογής:",
		stopHeader: "Επιλέξατε απενεργοποίηση της εφαρμογής:",
		startMessage: "Είστε σίγουρος ότι θέλετε να ενεργοποιήσετε την εφαρμογή;",
		stopMessage: "Είστε σίγουρος ότι θέλετε να απενεργοποιήσετε την εφαρμογή;",
		activation: "Ενεργοποίηση",
		deactivation: "Απενεργοποίηση",
		accept: "Αποδοχή",
		cancel: "Ακύρωση",
		updateApp: "Υπάρχει καινούρια έκδοση της εφαρμογής",
		upgradeToUpdateApp: "Υπάρχει καινούρια έκδοση της εφαρμογής. Απαιτείται ενημέρωση λογισμικού συσκευής",
		uninstall: "Απεγκατάσταση",
		uninstallText: "Είστε σίγουρος ότι θέλετε να απεγκαταστήσετε αυτή την εφαρμογή;",
		uninstallMessage: "Η εφαρμογή απεγκαθίσταται από την Έλσα σας.",
		update: "Αλλαγή πληροφοριών συσκευής",
		updateSuccess: "Η συσκευή ενημερώθηκε επιτυχώς.",
		updateOwner: "Αλλαγή ιδιοκτήτη συσκευής",
		updateOwnerSuccess: "Ο ιδιοκτήτης της συσκευής ενημερώθηκε επιτυχώς.",
		startSuccess: "Η εφαρμογή ενεργοποιείται.",
		stopSuccess: "Η εφαρμογή απενεργοποιείται.",
		criticalError: "Κρίσιμο σφάλμα",
		message: "Μήνυμα",
	},
	SoftwareRelease: {
		createTitle: "Δημιουργία νέας έκδοσης λογισμικού",
		editTitle: "Επεξεργασία έκδοσης λογισμικού",
		deleteTitle: "Διαγραφή έκδοσης λογισμικού",
		version: "Έκδοση",
		description: "Περιγραφή",
		filePlaceholder: "Επιλέξετε αρχείο .py",
		fileButtonPlaceholder: "Αναζήτηση",
		create: "Δημιουργία",
		edit: "Επεξεργασία",
		delete: "Διαγραφή",
		showModules: "Modules...",
		modules: "Modules",
		module: "Module",
		type: "Τύπος",
		name: "Όνομα",
		apply: "Εφαρμογή",
		cancel: "Ακύρωση",
	},
	DeviceSettings: {
		deviceSettings: "Ρυθμίσεις συσκευών",
		createDevice: "Δημιουργία συσκευής",
		name: "Όνομα συσκευής",
		serial: "Σειριακός αριθμός",
		description: "Περιγραφή",
		mac: "MAC διεύθυνση",
		version: "Έκδοση",
		brokerUsername: "Όνομα broker",
		brokerPassword: "Κωδικός broker",
		create: "Δημιουργία",
		editDevice: "Επεξεργασία συσκευής",
		edit: "Επεξεργασία",
		deleteDevice: "Διαγραφή συσκευής",
		delete: "Διαγραφή",
	},
	FastDeploy: {
		title: "Έλεγχος εφαρμογής",
		appName: "Όνομα εφαρμογής",
		filePlaceholder: "Επιλέξτε tar.gz",
		fileButtonPlaceholder: "Αναζήτηση",
		fastDeploy: "Ανάπτυξη",
	},
	Modules: {
		title: "Διαχείρηση modules",
		module: "Module",
		name: "Unix Όνομα",
		heartbeat: "Heartbeat",
		logs: "Logs",
		release: "Release",
		actions: "Επιλογές",
		add: "Προσθήκη module",
		createModule: "Δημιουργία module",
		selectName: "Unix όνομα module",
		selectDisplayName: "Όνομα module",
		create: "Δημιουργία",
		editSuccess: "Το module ενημερώθηκε επιτυχώς.",
		deleteModule: "Είστε σίγουρος ότι θέλετε να διαγράψετε αυτό το module;",
		delete: "Διαγραφή",
		deleteSuccess: "Το module διαγράφηκε επιτυχώς.",
		cancel: "Ακύρωση",
	},
	Types: {
		createTitle: "Δημιουργία νέου τύπου συσκευής",
		editTitle: "Επεξεργασία τύπου συσκευής",
		deleteTitle: "Διαγραφή τύπου συσκευής",
		name: "Όνομα",
		host: "Host",
		port: "Port",
		backendUsername: "Όνομα backend",
		backendPassword: "Κωδικός backend",
		create: "Δημιουργία",
		edit: "Επεξεργασία",
		delete: "Διαγραφή",
		showModules: "Modules...",
		modules: "Modules",
		module: "Module",
		type: "Τύπος",
		apply: "Εφαρμογή",
		cancel: "Ακύρωση",
	},
	Patients: {
		title: "Elsa χρήστες",
		name: "Όνομα",
		ehealthPassUsername: "eHealthpass όνομα",
		device: "Συσκευή",
		actions: "Επιλογές",
		addElder: "Προσθήκη χρήστη",
		deletePatient: "Είστε σίγουρος ότι θέλετε να διαγράψετε αυτόν τον χρήστη;",
		add: "Προσθήκη",
		selectDevice: "Επιλέξτε συσκευή",
		select: "Επιλογή",
		editSuccess: "Ο χρήστης ενημερώθηκε επιτυχώς.",
		editFail: "Τα στοιχεία χρήστη δεν είναι σωστά.",
		delete: "Διαγραφή",
		cancel: "Ακύρωση",
		deleteSuccess: "Ο χρήστης διαγράφηκε επιτυχώς.",
	},
	Contacts: {
		title: "Επαφές",
		name: "Όνομα",
		email: "Email",
		phone: "Τηλέφωνο",
		actions: "Επιλογές",
		addContact: "Προσθήκη επαφής",
		add: "Προσθήκη",
		cancel: "Ακύρωση",
		editSuccess: "Η επαφή ενημερώθηκε επιτυχώς.",
		editFail: "Τα στοιχεία επαφής δεν είναι σωστά.",
		deleteContact: "Είστε σίγουρος ότι θέλετε να διαγράψετε αυτή την επαφή;",
		deleteSuccess: "Η επαφή διαγράφηκε επιτυχώς.",
		delete: "Διαγραφή",
	},
	Instructions: {
		title: "Οδηγίες δημιουργίας εφαρμογών",
	},
	Calendar: {
		addCalendarEvent: "Προσθήκη προγραμματισμένης εφαρμογής",
		never: "Χωρίς επανάληψη",
		day: "Επανάλαβε κάθε μέρα",
		week: "Επανάλαβε κάθε εβδομάδα",
		biweek: "Επανάλαβε κάθε δύο εβδομάδες",
		month: "Επανάλαβε κάθε μήνα",
		year: "Επανάλαβε κάθε χρόνο",
		until: "Μέχρι :",
		startOfDayEvent: "Στην αρχή της μέρας",
		selectApp: "Επίλεξε εφαρμογή",
		startDateRequired: "Η ημερομηνία έναρξης είναι απαραίτητη",
		startTimeRequired: "Η ώρα έναρξης είναι απαραίτητη",
		repeatUntilRequired: "Η ημερομηνία λήξης είναι απαραίτητη",
		upcomingEvents: "Επικείμενες εκτελέσεις εφαρμογών",
		deleteEvent: "Είστε σίγουρος ότι θέλετε να διαγράψετε αυτή την επικείμενη εκτέλεση εφαρμογής;",
		deleteEventSuccessful: "Η επικείμενη εκτέλεση εφαρμογής διαγράφηκε επιτυχώς.",
		deleteEventFail: "Υπήρξε κάποιο πρόβλημα κατά τη διαγραφή.",
		delete: "Διαγραφή",
		cancel: "Ακύρωση",
		noEvents: "Δεν υπάρχουν επικείμενες εκτελέσεις εφαρμογών!",
		start: "Έναρξη",
		description: "Περιγραφή",
		timeDescription: "Μπορείτε να επιλέξετε οποιαδήποτε ώρα επιθυμείτε αλλά οι εφαρμογές θα εκκινήσουν στις :00, :15, :30 ή :45.",
	},
	Footer: { financing: "Η ανάπτυξη του παρόντος ιστοτόπου υποστηρίζεται απο το έργο ΣΥΤΗΕΣ - Σύστημα Υποστήριξης Τρίτης Ηλικίας με Έξυπνες Συσκευές, \nπου εκτελείται στα πλαίσια του πρώτου κύκλου της δράσης Ερευνώ-Δημιουργώ-Καινοτομώ με κωδικό Τ1ΕΔΚ-02347." },
};

export default localesGR;
