import i18n from "i18next";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import elLocale from "../locales/el-GR";
import enLocale from "../locales/en-US";

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
	name: "customCookieDetector",
	lookup: () => {
		const ui = Cookies.get("ui");
		if (ui) {
			return JSON.parse(ui).lng;
		}

		return "";
	},
	cacheUserLanguage: () => {},
});

i18n
	.use(languageDetector)
	.use(initReactI18next)
	.on("languageChanged", (lng) => {
		if (lng === "el-GR") {
			i18n.addResourceBundle("el-GR", "translations", elLocale);
		} else {
			i18n.addResourceBundle("en-US", "translations", enLocale);
		}
	})
	.init({
		detection: {
			order: ["customCookieDetector", "path", "htmlTag", "navigator"],
			lookupFromPathIndex: 0,
		},
		resources: {},
		interpolation: { escapeValue: false },
		fallbackLng: ["en-US"],
		ns: ["translations"],
		defaultNS: "translations",
	});

export default i18n;
