import { Nav, NavItem } from "@trendmicro/react-sidenav";
import { Icon, Text } from "@blueprintjs/core";
import { path } from "ramda";
import styled from "styled-components";
import propTypes from "prop-types";

import { ConditionalRender } from "../common/ui";

const primaryColor = path(["theme", "colors", "primary"]);
const secondaryColor = path(["theme", "colors", "secondary"]);
const greyColor = path(["theme", "colors", "grey"]);
const darkBlueColor = path(["theme", "colors", "darkBlue"]);

const ExpandedItemText = styled(Text)`
	font-size: ${path(["theme", "fontSizes", 4])};
	color: ${darkBlueColor};
	padding-left: 30px;
`;

const ExpandedItemContainer = styled.div`
    border-radius: 5px;
    background-color: ${greyColor};
    width: 200px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    -webkit-transition: all ease 1s;
    -moz-transition: all ease 1s;
    transition: all ease 1s;
    box-shadow: inset 0 0 0 0 #ffffff;
    &:hover {
        ${ExpandedItemText} {
            color: white;
        }
        box-shadow: inset 200px 0.01px 1px 1px ${primaryColor};
    }
`;

const ExpandedItemActiveContainer = styled(ExpandedItemContainer)`
	background-color: ${primaryColor};
	height: auto;
`;

const ExpandedItemActiveText = styled(ExpandedItemText)`
	color: ${secondaryColor};
`;

const ExpandedItemSquare = styled.div`
	width: 20px;
	position: absolute;
	height: 100%;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	background-color: ${primaryColor};
`;

const NavIcon = styled(Icon).attrs({ iconSize: 25 })`
    color: ${primaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: ${(props) => (props.selected ? primaryColor : secondaryColor)};
    width: 100%;
    height: 100%;
`;

const NavIconActive = styled(Icon).attrs({ iconSize: 25 })`
    color: ${secondaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: ${primaryColor};
    width: 100%;
    height: 100%;
`;

const ExpandedNavItem = ({ text, type }) => (
	<ExpandedItemContainer type={type}>
		<ExpandedItemSquare type={type} />
		<ExpandedItemText>{text}</ExpandedItemText>
	</ExpandedItemContainer>
);

const ExpandedNavItemActive = ({ text, type }) => (
	<ExpandedItemActiveContainer type={type}>
		<ExpandedItemActiveText>{text}</ExpandedItemActiveText>
	</ExpandedItemActiveContainer>
);

export const ExpandedMenu = ({ items, active, pushHistory, setExpanded: setExpandedNav }) => {
	const types = Object.keys(items);
	return (
		<Nav>
			{types.map((type) => items[type].map((item) => (
				<NavItem
					key={item.key}
					style={{ margin: "10px 0" }}
					eventKey={item.key}
					onSelect={(eventKey) => {
						setExpandedNav(false);
						pushHistory(eventKey);
					}}
				>
					<ConditionalRender condition={`/${active}` === item.key}>
						<ExpandedNavItemActive
							type={type}
							text={item.text}
						/>
						<ExpandedNavItem text={item.text} type={type} />
					</ConditionalRender>
				</NavItem>
			)))}
		</Nav>
	);
};

export const Menu = ({ items, active, pushHistory, setExpanded: setExpandedNav }) => {
	const types = Object.keys(items);
	return (
		<Nav>
			{types.map((type) => items[type].map((item) => (
				<NavItem
					key={item.key}
					style={{ margin: "10px 0" }}
					eventKey={item.key}
					onSelect={(eventKey) => {
						setExpandedNav(false);
						pushHistory(eventKey);
					}}
				>
					<ConditionalRender condition={`/${active}` === item.key}>
						<NavIconActive
							type={type}
							icon={item.icon}
						/>
						<NavIcon
							type={type}
							icon={item.icon}
						/>
					</ConditionalRender>
				</NavItem>
			)))}
		</Nav>
	);
};

const itemPropType = propTypes.shape({
	key: propTypes.string.isRequired,
	icon: propTypes.string.isRequired,
	text: propTypes.string.isRequired,
});

const itemsPropType = propTypes.arrayOf(itemPropType).isRequired;

const menuProptypes = {
	pushHistory: propTypes.func.isRequired,
	setExpanded: propTypes.func.isRequired,
	active: propTypes.string.isRequired,
	items: propTypes.shape({
		apps: itemsPropType,
		devices: itemsPropType,
	}).isRequired,
};

Menu.propTypes = menuProptypes;

ExpandedMenu.propTypes = menuProptypes;
