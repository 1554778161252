import styled from "styled-components";
import { fontSize } from "styled-system";

import StyledHeader from "../ui/styled-header";

const InputLabel = styled(StyledHeader).attrs(() => ({ fontSize: [1, 3] }))`
  margin: 0;
  color: black;
  text-align: left;
  margin-bottom: 10;
  ${fontSize};
`;

export default InputLabel;
