import { path } from "ramda";
import propTypes from "prop-types";
import styled from "styled-components";
import { layout } from "styled-system";

import sitiesDevice from "../../../assets/Sities_device.png";

const StyledDevice = styled.img.attrs(() => ({
	src: sitiesDevice,
	alt: "device icon",
	width: [80, 100],
	height: [80, 100],
}))`${layout}
	border-radius: 50%;
	border: 15px solid ${(props) => (props.status === "add" ? path(["theme", "colors", "primary"]) : "#B7B7B7")};
	background-color: ${(props) => (props.status === "add" ? path(["theme", "colors", "primary"]) : "#B7B7B7")};
	margin-left: auto;
	margin-right: auto;
	margin-top: ${(props) => (props.status === "add" ? "21px" : "5px")};
	position: relative;
`;

StyledDevice.propTypes = { status: propTypes.string.isRequired };

export default StyledDevice;
