import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { path } from "ramda";
import propTypes from "prop-types";
import { lazy } from "react";

import { checkIsAuthenticated } from "../lib/utilities";

const SignInScreen = lazy(() => import("../views/sign-in/SignInScreen"));
const ForgotPasswordScreen = lazy(() => import("../views/forgot-password/ForgotPasswordScreen"));
const SignUpScreen = lazy(() => import("../views/sign-up/SignUpScreen"));
const ChangePasswordScreen = lazy(() => import("../views/change-password/ChangePasswordScreen"));
const PreferencesScreen = lazy(() => import("../views/preferences/PreferencesScreen"));
const MyElsaScreen = lazy(() => import("../views/my-elsa/MyElsaScreen"));
const MyElsaIdScreen = lazy(() => import("../views/my-elsa-id/MyElsaIdScreen"));
const AppSuggestionsScreen = lazy(() => import("../views/AppSuggestionsScreen"));
const MyApplicationsScreen = lazy(() => import("../views/MyApplicationsScreen"));
const AppScreen = lazy(() => import("../views/application/AppScreen"));
const UploadAppScreen = lazy(() => import("../views/upload-app/UploadAppScreen"));
const AllElsasScreen = lazy(() => import("../views/all-elsas/AllElsasScreen"));
const SoftwareReleaseScreen = lazy(() => import("../views/software-release/SoftwareReleaseScreen"));
const FastDeployScreen = lazy(() => import("../views/FastDeployScreen"));
const DeviceTypesScreen = lazy(() => import("../views/device-types/DeviceTypesScreen"));
const DeviceModulesScreen = lazy(() => import("../views/device-modules/DeviceModulesScreen"));
const PatientsScreen = lazy(() => import("../views/contacts/PatientsScreen"));
const ContactsScreen = lazy(() => import("../views/contacts/ContactsScreen"));
const InstructionsScreen = lazy(() => import("../views/InstructionsScreen"));

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/" />)}
	/>
);

const OwnerRoute = ({ component: Component, isAuthenticated, view, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (isAuthenticated && view === "owner" ? <Component {...props} view={view} /> : <Redirect to="/apps/suggestions" />)}
	/>
);

const DevRoute = ({ component: Component, isAuthenticated, view, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (isAuthenticated && view === "developer" ? <Component {...props} view={view} /> : <Redirect to="/apps/suggestions" />)}
	/>
);

const ProducerRoute = ({ component: Component, isAuthenticated, view, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (isAuthenticated && view === "producer" ? <Component {...props} view={view} /> : <Redirect to="/apps/suggestions" />)}
	/>
);

const OnlyForGuestRoute = ({ component: Component, isAuthenticated, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (isAuthenticated ? <Redirect to="/apps/suggestions" /> : <Component {...props} />)}
	/>
);

const AppRoutes = ({ isAuthenticated, view }) => (
	<Switch>
		<OnlyForGuestRoute
			path="/signin"
			isAuthenticated={isAuthenticated}
			component={SignInScreen}
		/>
		<OnlyForGuestRoute
			path="/signup"
			isAuthenticated={isAuthenticated}
			component={SignUpScreen}
		/>
		<OnlyForGuestRoute
			path="/forgotpassword"
			isAuthenticated={isAuthenticated}
			component={ForgotPasswordScreen}
		/>
		<OnlyForGuestRoute
			path="/changepassword"
			isAuthenticated={isAuthenticated}
			component={ChangePasswordScreen}
		/>
		<PrivateRoute
			exact
			path="/apps/suggestions"
			view={view}
			isAuthenticated={isAuthenticated}
			component={AppSuggestionsScreen}
		/>
		<PrivateRoute
			exact
			path="/apps"
			isAuthenticated={isAuthenticated}
			component={MyApplicationsScreen}
		/>
		<DevRoute
			exact
			path="/apps/upload"
			view={view}
			isAuthenticated={isAuthenticated}
			component={(props) => (<UploadAppScreen {...props} action="create" />)}
		/>
		<DevRoute
			exact
			path="/apps/:appId/edit"
			view={view}
			isAuthenticated={isAuthenticated}
			component={(props) => (<UploadAppScreen {...props} action="edit" />)}
		/>
		<DevRoute
			exact
			path="/fastdeploy"
			view={view}
			isAuthenticated={isAuthenticated}
			component={FastDeployScreen}
		/>
		<DevRoute
			exact
			path="/instructions"
			view={view}
			isAuthenticated={isAuthenticated}
			component={InstructionsScreen}
		/>
		<PrivateRoute
			exact
			path="/apps/:appId"
			view={view}
			isAuthenticated={isAuthenticated}
			component={AppScreen}
		/>
		<PrivateRoute
			path="/preferences"
			view={view}
			isAuthenticated={isAuthenticated}
			component={PreferencesScreen}
		/>
		<OwnerRoute
			exact
			path="/myelsa/:elsaId"
			view={view}
			isAuthenticated={isAuthenticated}
			component={MyElsaIdScreen}
		/>
		<OwnerRoute
			exact
			path="/myelsa"
			view={view}
			isAuthenticated={isAuthenticated}
			component={MyElsaScreen}
		/>
		<OwnerRoute
			exact
			path="/patients"
			view={view}
			isAuthenticated={isAuthenticated}
			component={PatientsScreen}
		/>
		<OwnerRoute
			exact
			path="/patients/:patientId/contacts"
			view={view}
			isAuthenticated={isAuthenticated}
			component={ContactsScreen}
		/>
		<ProducerRoute
			exact
			path="/devices"
			view={view}
			isAuthenticated={isAuthenticated}
			component={AllElsasScreen}
		/>
		<ProducerRoute
			exact
			path="/devices/:elsaId"
			view={view}
			isAuthenticated={isAuthenticated}
			component={MyElsaIdScreen}
		/>
		<ProducerRoute
			exact
			path="/release"
			view={view}
			isAuthenticated={isAuthenticated}
			component={SoftwareReleaseScreen}
		/>
		<ProducerRoute
			exact
			path="/types"
			view={view}
			isAuthenticated={isAuthenticated}
			component={DeviceTypesScreen}
		/>
		<ProducerRoute
			exact
			path="/modules"
			view={view}
			isAuthenticated={isAuthenticated}
			component={DeviceModulesScreen}
		/>
		<Redirect from="*" to="/signin" />
	</Switch>
);

AppRoutes.propTypes = { isAuthenticated: propTypes.bool.isRequired };

const mapState = (state) => ({ isAuthenticated: checkIsAuthenticated(state), view: path(["ui", "view"], state) });

export default connect(mapState)(AppRoutes);
