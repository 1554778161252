import { Suspense } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Box } from "rebass/styled-components";

import { checkIsAuthenticated } from "../lib/utilities";

import { StyledSpinner } from "./common/ui";
import SideNav from "./side-navigation/SideNavigation";

const MainContainer = styled.div`
	display: flex;
	flex: 1;
`;
const MainBox = styled(Box)`
	min-height: calc(100vh - 100px);
	height: 100%;
	width: 100%;
`;

const MainBox2 = styled(Box)`
	min-height: calc(100vh - 100px);
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const AppMain = ({ children, isAuthenticated }) => (
	<MainContainer>
		{isAuthenticated && <SideNav />}
		<Suspense fallback={<MainBox2><StyledSpinner /></MainBox2>}>
			<MainBox bg="grey">{children}</MainBox>
		</Suspense>
	</MainContainer>
);

AppMain.propTypes = { isAuthenticated: propTypes.bool };
AppMain.defaultProps = { isAuthenticated: false };

const mapState = (state) => ({ isAuthenticated: checkIsAuthenticated(state) });

export default withRouter(connect(mapState)(AppMain));
