import useSWR from "swr";

import { getProfile } from "../api/profiles";
import { getAllDevices, getDevices } from "../api/devices";
import { getAll, getApp, getMyApps, getNew, getSuggestions } from "../api/apps";

export const useProfile = (userId) => {
	const { data, error, mutate } = useSWR(`useProfile${userId}`, () => getProfile(userId).then((res) => res.profile));
	return { profile: data, isLoading: !data, error, mutate };
};

export const useDevices = (allDevices = false) => {
	const { data, error, mutate } = useSWR(`useDevices${allDevices}`, () => (allDevices ? getAllDevices() : getDevices()).then((res) => res.devices));
	return { devices: data, isLoading: !data, error, mutate };
};

export const useApp = (appId) => {
	const { data, error, mutate } = useSWR(`useApp${appId}`, () => getApp(appId).then((res) => res.app));
	return { app: data, isLoading: !data, error, mutate };
};

export const useApps = (userId, developerApps = false) => {
	const { data, error, mutate } = useSWR(`useApps${developerApps}`, () => (developerApps ? getMyApps(userId) : getAll()).then((res) => res.apps));
	return { apps: data, isLoading: !data, error, mutate };
};

export const useNewApps = () => {
	const { data, error, mutate } = useSWR("useNewApps", () => getNew().then((res) => res.apps));
	return { newApps: data, isLoading: !data, error, mutate };
};

export const useSuggestions = () => {
	const { data, error, mutate } = useSWR("useSuggestions", () => getSuggestions().then((res) => res.apps));
	return { suggestions: data, isLoading: !data, error, mutate };
};
