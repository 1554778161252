import { Client } from "@stomp/stompjs";
import { useEffect, useState } from "react";

import actions from "../actions";
import { getRunning } from "../api/apps";
import { store } from "../plugins/initialize-store";
import { getAllDevices, getDevice } from "../api/devices";

const login = "sities-fe";
const passcode = "s!t!35-f3";
const brokerURL = "wss://issel08.ee.auth.gr/rabbit/web-stomp";

export const useDevicesSocket = () => {
	const [devices, setDevices] = useState({});
	const [version, setVersion] = useState({});

	useEffect(() => {
		let isMounted = true;

		(async () => {
			try {
				const { devices: dbDevices } = await getAllDevices();
				if (isMounted) {
					setVersion(Object.fromEntries(dbDevices.map((d) => [d.name, d.version])));
				}
			} catch (error) {
				store.dispatch(actions.ui.addError({ message: error }));
			}
		})();

		return () => { isMounted = false; };
	}, []);

	useEffect(() => {
		const stompClient = new Client({
			connectHeaders: { login, passcode },
			brokerURL,
			reconnectDelay: 2000,
			onConnect() {
				stompClient.subscribe("/topic/thing.*.*.heartbeat", (msg) => {
					const [, deviceName] = msg.headers.destination.split(".");
					setDevices((p) => ({
						...p,
						[deviceName]: Date.now(),
					}));
				});
			},
		});
		stompClient.activate();

		return () => {
			try {
				stompClient.unsubscribe("/topic/thing.*.*.heartbeat");
				stompClient.deactivate();
			} catch { /** empty */ }
		};
	}, []);

	return {
		devices: Object.fromEntries(
			Object.entries(devices).map(
				([deviceName, ts]) => [deviceName, Date.now() - ts < 20_000],
			),
		),
		version,
	};
};

export const useDeviceSocket = (deviceId) => {
	const [version, setVersion] = useState();
	const [device, setDevice] = useState({ status: "offline" });

	useEffect(() => {
		let isMounted = true;

		(async () => {
			try {
				const { device: dbDevice } = await getDevice(deviceId);
				if (isMounted) {
					setDevice(dbDevice);
					setVersion(dbDevice.version);
				}
			} catch (error) {
				store.dispatch(actions.ui.addError({ message: error }));
			}
		})();

		return () => { isMounted = false; };
	}, [deviceId]);

	useEffect(() => {
		if (device.name) {
			const stompClient = new Client({
				connectHeaders: { login, passcode },
				brokerURL,
				reconnectDelay: 2000,
				onConnect() {
					stompClient.subscribe(`/topic/thing.${device?.name}.*.heartbeat`, () => {
						setDevice((p) => ({ ...p, status: Date.now() }));
					});
				},
			});
			stompClient.activate();

			return () => {
				try {
					stompClient.unsubscribe(`/topic/thing.${device?.name}.*.heartbeat`);
					stompClient.deactivate();
				} catch { /** empty */ }
			};
		}

		return {};
	}, [device?.name]);

	return {
		version,
		device: { ...device, status: (Date.now() - device?.status) < 20_000 ? "online" : "offline" },
	};
};

export const useDeviceListenSocket = (deviceId) => {
	const [device, setDevice] = useState({ listenResults: [] });

	useEffect(() => {
		let isMounted = true;

		(async () => {
			try {
				const { device: dbDevice } = await getDevice(deviceId);
				if (isMounted) {
					setDevice({ ...dbDevice, listenResults: [] });
				}
			} catch (error) {
				store.dispatch(actions.ui.addError({ message: error }));
			}
		})();

		return () => { isMounted = false; };
	}, [deviceId]);

	useEffect(() => {
		if (device.name) {
			const stompClient = new Client({
				connectHeaders: { login, passcode },
				brokerURL,
				reconnectDelay: 2000,
				onConnect() {
					stompClient.subscribe(`/topic/thing.${device?.name}.streamsim.microphone.listen`, (msg) => {
						setDevice((p) => ({ ...p, listenResults: [...p.listenResults, `${(new Date()).toLocaleString("en-US", { timeZone: "Europe/Athens" })}: ${JSON.parse(msg.body).text}`] }));
					});
				},
			});
			stompClient.activate();

			return () => {
				try {
					stompClient.unsubscribe(`/topic/thing.${device?.name}.streamsim.microphone.listen`);
					stompClient.deactivate();
				} catch { /** empty */ }
			};
		}

		return {};
	}, [device?.name]);

	return device?.listenResults;
};

export const useDeviceUpgradeSocket = (device) => {
	const [upgradeStatus, setUpgradeStatus] = useState();

	useEffect(() => {
		if (device.name) {
			const stompClient = new Client({
				connectHeaders: { login, passcode },
				brokerURL,
				reconnectDelay: 2000,
				onConnect() {
					stompClient.subscribe(`/topic/thing.${device?.name}.device_upgrader.notify`, (msg) => {
						setUpgradeStatus(JSON.stringify(JSON.parse(msg.body)));
					});
				},
			});
			stompClient.activate();

			return () => {
				try {
					stompClient.unsubscribe(`/topic/thing.${device?.name}.device_upgrader.notify`);
					stompClient.deactivate();
				} catch { /** empty */ }
			};
		}

		return {};
	}, [device?.name]);

	return { upgradeStatus };
};

export const useDeviceStatsSocket = (device) => {
	const [stats, setStats] = useState();

	useEffect(() => {
		if (device.name) {
			const stompClient = new Client({
				connectHeaders: { login, passcode },
				brokerURL,
				reconnectDelay: 2000,
				onConnect() {
					stompClient.subscribe(`/topic/thing.${device?.name}.dihma.stats`, (msg) => {
						setStats(JSON.parse(msg.body));
					});
				},
			});
			stompClient.activate();

			return () => {
				try {
					stompClient.unsubscribe(`/topic/thing.${device?.name}.dihma.stats`);
					stompClient.deactivate();
				} catch { /** empty */ }
			};
		}

		return {};
	}, [device?.name]);

	return { stats };
};

export const useDeviceComponentsSocket = (device) => {
	const [components, setComponents] = useState({
		app_manager: 0,
		tmateagent: 0,
		dihma: 0,
		streamsim: 0,
		rhasspy: 0,
		wifi_server: 0,
		device_upgrader: 0,
		device_handler: 0,
		custom_ui_handler: 0,
		device_remote_logger: 0,
		rasa_nlu: 0,
		raven_wakeword: 0,
	});

	useEffect(() => {
		if (device.name) {
			const stompClient = new Client({
				connectHeaders: { login, passcode },
				brokerURL,
				reconnectDelay: 2000,
				onConnect() {
					stompClient.subscribe(`/topic/thing.${device?.name}.*.heartbeat`, (msg) => {
						const component = msg.headers.destination.split(".")[2];
						setComponents((p) => ({ ...p, [component]: Date.now() }));
					});
				},
			});
			stompClient.activate();

			return () => {
				try {
					stompClient.unsubscribe(`/topic/thing.${device?.name}.*.heartbeat`);
					stompClient.deactivate();
				} catch { /** empty */ }
			};
		}

		return {};
	}, [device?.name]);

	return { components: Object.fromEntries(
		Object.entries(components).map(
			([component, ts]) => [component, Date.now() - ts < 20_000],
		),
	) };
};

export const useDeviceRunningAppsSocket = (device) => {
	const [runningApps, setRunningApps] = useState([]);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			try {
				if (device?.id && (device.type === "ELSA" || device.type === "ELSA2")) {
					const { apps } = await getRunning(device.id);
					if (isMounted) {
						setRunningApps(apps);
					}
				}
			} catch (error) {
				store.dispatch(actions.ui.addError({ message: error }));
			}
		})();

		return () => { isMounted = false; };
	}, [device.id, device.type]);

	useEffect(() => {
		if (device.name) {
			const stompClient = new Client({
				connectHeaders: { login, passcode },
				brokerURL,
				reconnectDelay: 2000,
				onConnect() {
					stompClient.subscribe(`/topic/thing.${device?.name}.app_manager.app.*.*`, (msg) => {
						const appName = msg.headers.destination.split(".")[4];
						const status = msg.headers.destination.split(".")[5];

						setRunningApps((p) => {
							if (status === "started") { return [...p, appName]; }
							return p.filter((app) => app !== appName);
						});
					});
				},
			});
			stompClient.activate();

			return () => {
				try {
					stompClient.unsubscribe(`/topic/thing.${device?.name}.*.heartbeat`);
					stompClient.deactivate();
				} catch { /** empty */ }
			};
		}

		return {};
	}, [device?.name]);

	return { runningApps };
};
