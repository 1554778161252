import styled from "styled-components";
import { Text } from "@blueprintjs/core";
import { path } from "ramda";
import { fontSize } from "styled-system";

const StyledDescription = styled(Text).attrs((props) => ({ fontSize: props.fontSize }))`
 color: ${path(["theme", "colors", "darkGrey"])};
 line-height: 12px;
 height: 48px;
 overflow: hidden;
 word-break: break-word;
 ${fontSize};
`;

StyledDescription.defaultProps = { fontSize: [0, 1] };

export default StyledDescription;
