import Cookies from "js-cookie";
import { T, cond, isNil, path } from "ramda";

import { reducer } from "../lib/redux-helpers";

export const initialState = Cookies.get("auth") ? JSON.parse(Cookies.get("auth")) : { user: { id: "", roles: [], email: "" }, token: null };

const set = reducer("AUTH.SET", (state, { payload }) => ({
	...state,
	user: payload.user,
	token: payload.token,
}));

const clear = reducer("AUTH.CLEAR", (state) => ({
	...state,
	user: { id: "", roles: [], email: "" },
	token: null,
}));

const defaultCase = [T, (state) => (isNil(path(["token"], state)) ? initialState : state)];

export default cond([
	clear,
	set,
	defaultCase,
]);
