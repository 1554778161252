import Cookies from "js-cookie";
import { allPass, isNil, path, replace, ifElse, isEmpty } from "ramda";

export const getFormErrorsField = (field, errors, touched) => {
	const p = [...field.split(".")];
	return path(p, errors) && path(p, touched) && path(p, errors);
};

export const getInputValueByName = (values, name) => path([...name.split(".")], values);

export const getListOfInputValuesByName = (values, name) => {
	const listOfValues = path([name], values);
	let listOfNames = "";
	if (listOfValues) {
		for (const listOfValue of listOfValues) {
			listOfNames += `${listOfValue.name}, `;
		}
	}

	return listOfNames.slice(0, -2);
};

export const flexClassName = (flex) => `flex-${String(flex).replace(".", "_")}`;

export const checkIsAuthenticated = allPass([
	(state) => !isNil(path(["auth", "token"], state)),
	(state) => !isEmpty(path(["auth", "user"], state)),
]);

export const getRoles = (state) => state.auth.user.roles;

export const getTokenId = (state) => state.auth.user.tokenId;

export const changeLanguage = (lng) => {
	const {
		location: { pathname },
		location,
	} = window;
	ifElse(
		() => lng === "en-US",
		() => location.replace(replace("/el-GR/", "/en-US/", pathname)),
		() => location.replace(replace("/en-US/", "/el-GR/", pathname)),
	)();
};

export const sanitize = (str) => str.replace(/[^ A-Za-zΆ-ΏΑ-Ωά-ώ]/g, "");

export const disableSubmitOnReturn = (keyEvent) => {
	if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
		keyEvent.preventDefault();
	}
};

export const findAvg = (ratings) => ratings.reduce((a, b) => a + b, 0) / ratings.length;

export const getQueryParams = (query) => {
	const params = {};
	for (const q of query.slice(1).split("&")) {
		const index = q.indexOf("=");
		const key = q.slice(0, Math.max(0, index));
		const value = q.slice(index + 1);
		params[key] = value;
	}

	return params;
};

export const updateCookie = (name, patch, options) => {
	const currentValue = Cookies.get(name);
	const newValue = currentValue ? { ...JSON.parse(currentValue), ...patch } : patch;
	Cookies.set(name, JSON.stringify(newValue), options || { path: "/" });
};
