import { path } from "ramda";
import { useEffect } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";

import actions from "../actions";

import { Toaster } from "./common/ui";

const haveNewError = (t, errors) => t.getToasts().length < errors.length;

const ErrorBoundary = ({ errors, children, removeError }) => {
	useEffect(() => {
		if (errors && haveNewError(Toaster, errors)) {
			const error = errors[errors.length - 1];
			// regex to catch error message of /stop application
			const regex = new RegExp(/Application <.*> is not running/, "igm");
			const userNotFound = new RegExp(/User not found/, "igm");
			const releaseNotFound = new RegExp(/Resources Error: Release doesnt't exist/, "igm");
			const unavailableVersion = new RegExp(/Version isn't available\. /, "igm");
			if (regex.test(error.error)
				|| userNotFound.test(error.message)
				|| releaseNotFound.test(error.message)
				|| unavailableVersion.test(error.message)
			) {
				//* * empty */
			} else if (error) {
				console.log(error);
				Toaster.show({
					intent: "danger",
					message: error?.message?.toString() || "An error has occured!",
					onDismiss: () => {
						removeError(error.id);
					},
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors]);
	return children;
};

ErrorBoundary.propTypes = {
	errors: propTypes.arrayOf(propTypes.object),
	children: propTypes.node.isRequired,
	removeError: propTypes.func.isRequired,
};

ErrorBoundary.defaultProps = { errors: [] };

const mapState = (state) => ({ errors: path(["ui", "errors"], state) });

const mapDispatch = (dispatch) => ({
	removeError: (id) => {
		dispatch(actions.ui.removeError(id));
	},
});

export default connect(mapState, mapDispatch)(ErrorBoundary);
