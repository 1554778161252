import propTypes from "prop-types";

const ConditionalRender = ({ condition, children }) => (
	Array.isArray(children)
		? condition
			? children[0]
			: children[1]
		: condition && children
);

export default ConditionalRender;

ConditionalRender.propTypes = {
	condition: propTypes.bool.isRequired,
	children: propTypes.node.isRequired,
};
