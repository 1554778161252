import { useState, useEffect } from "react";
import { path } from "ramda";
import propTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SideNav, { Toggle } from "@trendmicro/react-sidenav";

import { ConditionalRender } from "../common/ui";

import { Menu, ExpandedMenu } from "./menu";

const StyledSideNav = styled(SideNav)`
	position: relative;
	background-color: white;
	z-index: 1;
`;

const SideNavigation = ({ view, history: { push: pushHistory, location: { pathname } } }) => {
	const small = window.innerWidth < 600;
	const [expanded, setExpanded] = useState(!small);
	const [active, setActive] = useState(pathname.slice(1));
	const { t } = useTranslation();

	const ownerItems = {
		apps: [
			{
				text: t("SideNavigation.apps"),
				icon: "applications",
				key: "/apps/suggestions",
			},
		],
		devices: [
			{
				text: t("SideNavigation.elsa"),
				icon: "dashboard",
				key: "/myelsa",
			},
		],
		settings: [
			{
				text: t("SideNavigation.contacts"),
				icon: "people",
				key: "/patients",
			},
			// {
			// 	text: t("SideNavigation.events"),
			// 	icon: "calendar",
			// 	key: "/events",
			// },
		],
	};

	const developerItems = {
		apps: [
			{
				text: t("SideNavigation.apps"),
				icon: "applications",
				key: "/apps/suggestions",
			},
			{
				text: t("SideNavigation.myapps"),
				icon: "folder-close",
				key: "/apps",
			},
			{
				text: t("SideNavigation.addapp"),
				icon: "add",
				key: "/apps/upload",
			},
			{
				text: t("SideNavigation.fastdeploy"),
				icon: "lab-test",
				key: "/fastdeploy",
			},
		],
		devices: [],
		settings: [
			{
				text: t("SideNavigation.instructions"),
				icon: "manual",
				key: "/instructions",
			},
		],
	};

	const producerItems = {
		apps: [
			{
				text: t("SideNavigation.apps"),
				icon: "applications",
				key: "/apps/suggestions",
			},
		],
		devices: [
			{
				text: t("SideNavigation.devices"),
				icon: "control",
				key: "/devices",
			},
		],
		settings: [
			{
				text: t("SideNavigation.release"),
				icon: "upload",
				key: "/release",
			},
			{
				text: t("SideNavigation.types"),
				icon: "settings",
				key: "/types",
			},
			{
				text: t("SideNavigation.modules"),
				icon: "cube",
				key: "/modules",
			},
		],
	};

	useEffect(() => {
		const updatedActive = pathname.slice(1);
		if (active !== updatedActive) setActive(updatedActive);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	let items;
	if (view === "producer") {
		items = producerItems;
	} else if (view === "developer") {
		items = developerItems;
	} else {
		items = ownerItems;
	}

	return (
		<StyledSideNav expanded={expanded} onToggle={() => setExpanded(!expanded)}>
			<Toggle />
			<ConditionalRender condition={!expanded}>
				<Menu
					items={items}
					active={active}
					pushHistory={pushHistory}
					setExpanded={setExpanded}
				/>
				<ExpandedMenu
					items={items}
					active={active}
					pushHistory={pushHistory}
					setExpanded={setExpanded}
				/>
			</ConditionalRender>
		</StyledSideNav>
	);
};

const mapState = (state) => ({ view: path(["ui", "view"], state) });

export default connect(mapState)(withRouter(SideNavigation));

SideNavigation.propTypes = { history: propTypes.objectOf(propTypes.any).isRequired };
