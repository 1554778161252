import propTypes from "prop-types";
import { MenuItem } from "@blueprintjs/core";
import { concat } from "ramda";
import { Suggest } from "@blueprintjs/select";
import { useTranslation } from "react-i18next";

import { getFormErrorsField, flexClassName } from "../../../lib/utilities";
import { Highlighter, StyledFormGroup } from "../ui";
import { useWindowSize } from "../../../hooks";

import InputLabel from "./input-label";

export const renderItem = (item, { handleClick, modifiers, query }) => {
	if (!modifiers.matchesPredicate) {
		return null;
	}

	return (
		<MenuItem
			key={item}
			active={modifiers.active}
			text={<Highlighter text={item} highlight={query} />}
			onClick={handleClick}
		/>
	);
};

export const renderInput = (item) => item;

export const NoResults = () => {
	const { t } = useTranslation();
	return (
		<MenuItem disabled text={t("SuggestInput.noResults")} />
	);
};

export const Component = ({
	formikProps: { errors, touched, handleChange, handleBlur, values },
	name,
	intent,
	fill,
	className,
	placeholder,
	contentClassName,
	contentFlex,
	labelFlex,
	minimal,
	items,
	inline,
	label,
	disabled,
}) => {
	const { innerWidth } = useWindowSize();
	const large = innerWidth > 600;
	return (
		<StyledFormGroup
			className={concat(
				className,
				labelFlex ? ` label_${flexClassName(labelFlex)}` : "",
			)}
			inline={inline}
			label={<InputLabel>{label}</InputLabel>}
			contentClassName={concat(
				contentClassName,
				contentFlex ? ` ${flexClassName(contentFlex)}` : "",
			)}
			intent={getFormErrorsField(name, errors, touched) ? "danger" : intent}
			helperText={getFormErrorsField(name, errors, touched)}
		>
			<Suggest
				disabled={disabled}
				selectedItem={values[name]}
				value={values[name]}
				popoverProps={{ minimal }}
				fill={fill}
				items={items}
				itemRenderer={renderItem}
				itemPredicate={(query, item) => item.toLowerCase().includes(query.toLowerCase())}
				inputValueRenderer={renderInput}
				noResults={<NoResults />}
				activeItem={values[name]}
				inputProps={{
					name,
					onBlur: handleBlur,
					placeholder,
					value: values[name],
					autoComplete: "off",
					intent: getFormErrorsField(name, errors, touched) ? "danger" : intent,
					large,
				}}
				onItemSelect={(item) => handleChange({ target: { name, value: item } })}
			/>
		</StyledFormGroup>
	);
};

Component.propTypes = {
	formikProps: propTypes.objectOf(propTypes.any).isRequired,
	items: propTypes.arrayOf(propTypes.string).isRequired,
	name: propTypes.string,
	minimal: propTypes.bool,
	fill: propTypes.bool,
	placeholder: propTypes.string,
	className: propTypes.string,
	contentClassName: propTypes.string,
	labelFlex: propTypes.number,
	contentFlex: propTypes.number,
	inline: propTypes.bool,
	label: propTypes.string,
	disabled: propTypes.bool,
};

Component.defaultProps = {
	minimal: true,
	fill: false,
	labelFlex: null,
	placeholder: "",
	name: "",
	className: "",
	contentClassName: "",
	contentFlex: "",
	label: "",
	inline: false,
	disabled: false,
};

export default Component;
