import styled from "styled-components";
import { path } from "ramda";

const StyledLink = styled.a.attrs((props) => ({ href: props.href }))`
	:hover {
		text-decoration: none;
	}
	color: ${path(["theme", "colors", "primary"])};
	text-align: center;
`;

export default StyledLink;
